import React from 'react';
import { styled } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';

const PillButton = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'outlined' && prop !== 'style',
})(({ outlined, style }) => ({
  backgroundColor: outlined ? '#FFFFFF' : '#0097F5',
  color: outlined ? '#0097F5' : '#FFFFFF',
  border: outlined ? '2px solid #0097F5' : 'none',
  padding: '15px 60px',
  borderRadius: '50px',
  fontSize: '18px',
  fontWeight: '400',
  fontStyle: 'normal',
  lineHeight: 'normal',
  cursor: 'pointer',
  outline: 'none',
  transition: 'background-color 0.3s ease, color 0.3s ease',
  '&:hover': {
    backgroundColor: outlined ? '#F0F0F0' : '#007FFF',
  },
  '&:active': {
    backgroundColor: outlined ? '#E0E0E0' : '#005FCC',
  },
  '&.MuiButtonBase-root': {
    display: 'inline-flex',
    justifyContent: 'center',
    alignItems: 'center',
    textAlign: 'center',
  },
  ...style,
}));

const KodelabFilledPillButton = ({
  children,
  onClick,
  style = {},
  outlined = false,
  ...props
}) => {
  return (
    <PillButton onClick={onClick} outlined={outlined} style={style} {...props}>
      {children}
    </PillButton>
  );
};

export default KodelabFilledPillButton;