// src/components/Companies.js

import React, { useEffect, useState, useRef } from 'react';
import './Companies.css';
import CompanyList from './CompanyList';
import CompanyDetails from './CompanyDetails';
import EditCompanyForm from './EditCompanyForm';
import NewCompanyForm from './NewCompanyForm';
import NewCompany from './newCompanyFlow/NewCompany';
import CustomSnackbar from '../../../components/CustomSnackBar';

import useCompanies from './hooks/useCompanies';
import useProducts from './hooks/useProducts';
import useServices from './hooks/useServices';
import useCreateCompany from './hooks/useCreateCompany';
import useUpdateCompany from './hooks/useUpdateCompany';

function Companies() {
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [isCreating, setIsCreating] = useState(false);
  const [successSnackbarOpen, setSuccessSnackbarOpen] = useState(false);
  const [snackBarMessage, setSnackbarMessage] = useState('');
  const [step, setStep] = useState(1);
  // Custom hooks for data fetching and actions
  const {
    companies,
    isLoadingCompanies,
    companiesError,
    refetchCompanies,
  } = useCompanies();

  const { products, isLoadingProducts, productsError } = useProducts(selectedCompany);
  const { services, isLoadingServices, servicesError } = useServices(selectedCompany);

  const { isCreating: isCreatingCompany, createError, newCompany } = useCreateCompany();
  const { isUpdating, updateError, handleUpdateCompany } = useUpdateCompany();

  const containerRef = useRef(null);

  useEffect(() => {
    if (companies && companies.length > 0) {
      const matchingCompany = companies.find(
        (company) => company.company.pk === selectedCompany?.pk
      );
      setSelectedCompany(matchingCompany?.company || companies[0].company);
    }
  }, [companies]);

  useEffect(() => {
    if (selectedCompany && containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [selectedCompany]);

  //Make sure to reset step when we exit
  useEffect(() => {
    if (!isCreating) {
      setStep(1);
    }
  }, [isCreating]);

  // Handle selecting a company from the list
  const handleSelectCompany = (company) => {
    setSelectedCompany(company);
    setIsCreating(false);
    setIsEditing(false);
  };

  // Handle editing a company
  const handleEditCompany = (company) => {
    if (!company) {
      setIsEditing(false);
      return;
    }
    setSelectedCompany(company);
    setIsEditing(true);
    setIsCreating(false);
  };

  // Handle saving changes after editing
  const handleSaveChanges = async (updatedCompany) => {
    try {
      setSelectedCompany(updatedCompany);
      setIsEditing(false);
      setIsCreating(false);
      await refetchCompanies();
      setSnackbarMessage('Company updated successfully!')
      setSuccessSnackbarOpen(true);
      setStep(1);
    } catch (error) {
      alert(updateError || 'Failed to update company. Please try again.');
    }
  };

  // Handle initiating the creation of a new company
  const handleCreateNewCompany = () => {
    setIsCreating(true);
    setIsEditing(false);
    setSelectedCompany(null);
  };

  //Redundant?
  const handleCreateCompany = async (payload) => {
    try {
      const result = await newCompany(payload);
      await refetchCompanies();
      setSelectedCompany(result.company);
      setIsCreating(false);
      setSnackbarMessage('Company created successfully!')
      setSuccessSnackbarOpen(true);
    } catch (error) {
      console.log('error', error);
      alert(createError || 'Failed to create company. Please try again.');
    }
  };

  return (
    <div className="companies-page">
      {/* Success Snackbar */}
      <CustomSnackbar
        open={successSnackbarOpen}
        onClose={() => setSuccessSnackbarOpen(false)}
        message={snackBarMessage}
        backgroundColor="#BEF5BD"
        textColor="#155724"
        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
        autoHideDuration={3000}
      />

      {/* Company List */}

      <CompanyList
        companies={companies}
        isLoadingCompanies={isLoadingCompanies}
        fetchError={companiesError}
        selectedCompany={selectedCompany}
        onCompanyClick={handleSelectCompany}
        onEditCompany={handleEditCompany}
        mode={isCreating ? 'create' : isEditing ? 'edit' : 'none'}
        setIsCreating={setIsCreating}
        setIsEditing={setIsEditing}
        step={step}
        setStep={setStep}
      />

      {/* Main Content */}
      <main className="companies-main">
        {isCreating ? (
          <NewCompany
            step={step}
            setStep={setStep}
            onCreateCompany={handleCreateCompany}
          />
        ) : isEditing ? (
          // <EditCompanyForm
          //   company={selectedCompany}
          //   onEditCompany={handleEditCompany}
          //   onSaveChanges={handleSaveChanges}
          //   products={products}
          //   services={services}
          //   isUpdating={isUpdating}
          //   updateError={updateError}
          // />
          <NewCompany
            step={step}
            setStep={setStep}
            existingCompany={selectedCompany}
            mode="edit"
            onSaveChanges={handleSaveChanges}
            initialProducts={products}
            initialServices={services}
          />
        ) : selectedCompany ? (
          <CompanyDetails
            company={selectedCompany}
            setIsCreating={setIsCreating}
            onEditCompany={handleEditCompany}
            products={products}
            services={services}
            isLoadingProducts={isLoadingProducts}
            productsError={productsError}
            isLoadingServices={isLoadingServices}
            servicesError={servicesError}
            containerRef={containerRef}
          />
        ) : null}
      </main>
    </div>
  );
}

export default Companies;