import React from 'react';
import KodelabInput from '../../../../../components/KodelabInput';
import KodelabTextArea from '../../../../../components/KodelabTextArea';
import KodelabFilledPillButton from '../../../../../components/KodelabFilledPillButton';
import KodelabCircularBackButton from '../../../../../components/KodelabCircularBackButton';
import { ReactComponent as DeleteIcon } from '../../../../../assets/DeleteIcon.svg'

function ValuesCulture({ formState, errors, handleChange, handleNextStep, handlePreviousStep, handleValueChange, newValue, addValue, removeValue, values }) {
  return (
    <div className="company-values-and-culture">
      <div>
        <div className="company-details-header">
          <h3>Values & Culture</h3>
        </div>

        {/* Dynamic Values Input */}
        <div>
          <KodelabInput
            label="Values"
            name="name"
            placeholder="Enter the title"
            value={newValue.name}
            onChange={handleValueChange}
          />
          <KodelabTextArea
            name="tagline"
            placeholder="Enter the description"
            value={newValue.tagline}
            onChange={handleValueChange}
            inputStyle={{ marginBottom: '10px' }}
          />
          <KodelabFilledPillButton onClick={addValue} outlined style={{ padding: '11px 35px' }}>
            Add
          </KodelabFilledPillButton>
        </div>



        {/* List of Added Values */}
        <ul className="addable-list">
          {Object.entries(values).map(([key, tagline]) => (
            <li key={key} className="addable-item">
              <div className="addable-content">
                <strong>{key}</strong>
                <p>{tagline}</p>
              </div>
              <button type="button" className="delete-button" onClick={() => removeValue(key)}>
                <DeleteIcon />
              </button>
            </li>
          ))}
        </ul>

        <div className="form-group">
          <KodelabTextArea
          name="company_culture"
          placeholder="Enter the company's culture"
          label="Culture"
          onChange={handleChange}
          value={formState.company_culture}
          />
        </div>
        <div className="form-group">
          <KodelabTextArea
          name="benefits_perks"
          placeholder="Enter the company's benefits and perks here"
          label="Employee benefits and perks"
          onChange={handleChange}
          value={formState.benefits_perks}
          />
        </div>
      </div>
      <div className="new-company-footer">
        <KodelabCircularBackButton onClick={handlePreviousStep} />
        <KodelabFilledPillButton onClick={handleNextStep} style={{ padding: '15px 30px' }}>
          Next step
        </KodelabFilledPillButton>
      </div>
    </div>
  );
}

export default ValuesCulture;