import * as React from 'react';
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import { styled } from '@mui/system';

const blue = {
  100: '#DAECFF',
  200: '#b6daff',
  400: '#3399FF',
  500: '#007FFF',
  600: '#0072E5',
  900: '#003A75',
};

const grey = {
  50: '#F3F6F9',
  100: '#E5EAF2',
  200: '#DAE2ED',
  300: '#C7D0DD',
  400: '#B0B8C4',
  500: '#9DA8B7',
  600: '#6B7A90',
  700: '#434D5B',
  800: '#303740',
  900: '#1C2025',
};

const Textarea = styled(BaseTextareaAutosize)(
  ({ theme }) => `
    box-sizing: border-box;
    width: 100%;
    font-family: 'IBM Plex Sans', sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 1.5;
    padding: 15px;
    border-radius: 8px;
    color: ${theme.palette.mode === 'dark' ? grey[300] : grey[900]};
    background: ${theme.palette.mode === 'dark' ? grey[900] : '#fff'};
    border: 1px solid ${theme.palette.mode === 'dark' ? grey[700] : grey[200]};
    resize: vertical;
    min-height: 100px;
    margin-bottom: 10px;
    
    &::placeholder {
      color: #636c72;
    }

    &:hover {
      border-color: ${blue[400]};
    }

    &:focus {
      border-color: ${blue[400]};
      box-shadow: 0 0 0 3px ${theme.palette.mode === 'dark' ? blue[600] : blue[200]};
    }

    &:focus-visible {
      outline: 0;
    }
  `,
);

const KodelabTextArea = ({
  label,
  placeholder,
  style = {},
  labelStyle = {},
  inputStyle = {},
  required = false,
  ...props
}) => {
  const defaultLabelStyle = {
    display: 'block',
    marginBottom: '2px',
    fontSize: '16px',
    fontStyle: 'normal',
    fontWeight: '400',
    lineHeight: '22px',
    ...labelStyle,
  };

  return (
    <div style={style}>
      {label && <label style={defaultLabelStyle}>
        {label}
        {required && <span> *</span>}
        </label>}
      <Textarea placeholder={placeholder} style={inputStyle} {...props} />
    </div>
  );
};

export default KodelabTextArea;