// src/components/EditCandidate.js

import React, { useState } from 'react';
import useUpdateCandidate from './hooks/useUpdateCandidate';

const EditCandidate = ({ candidate, onCancelEdit, onFetchCandidates }) => {
    const [editCandidateData, setEditCandidateData] = useState({
        name: candidate.name,
        resume: candidate.resume,
    });

    const { isUpdating, updateError, handleUpdateCandidate } = useUpdateCandidate();

    const handleSaveCandidate = async () => {
        const payload = { pk: candidate.pk, ...editCandidateData };
        try {
            await handleUpdateCandidate(payload);
            alert('Candidate details updated successfully!');
            await onFetchCandidates();
            onCancelEdit();
        } catch (error) {
            alert('Failed to update candidate details. Please try again.');
        }
    };

    return (
        <div className="edit-candidate-box">
            <h2>Edit Candidate</h2>
            <label>
                Name:
                <input
                    type="text"
                    value={editCandidateData.name}
                    onChange={(e) =>
                        setEditCandidateData({ ...editCandidateData, name: e.target.value })
                    }
                />
            </label>
            <label>
                Resume:
                <textarea
                    value={editCandidateData.resume}
                    onChange={(e) =>
                        setEditCandidateData({ ...editCandidateData, resume: e.target.value })
                    }
                />
            </label>
            <button onClick={handleSaveCandidate} disabled={isUpdating}>
                {isUpdating ? 'Saving...' : 'Save'}
            </button>
            <button onClick={onCancelEdit}>Cancel</button>
            {updateError && <p className="error-message">{updateError}</p>}
        </div>
    );
};

export default EditCandidate;