import { React, useState } from 'react';
import Snackbar from '@mui/material/Snackbar';
import success_icon from '../../../../assets/BsCheck2.png';

import './SuccessStep.css';

import apiConfig from '../../../../config/apiConfig';

const SuccessStep = ({ vacancyId, handleVacancyCreated }) => {
  const vacancyLink = `${apiConfig.baseURL}/interview?id=${vacancyId}`;

  const copyToClipboard = () => {
    navigator.clipboard.writeText(vacancyLink);
    setOpen(true);
  };

  const [open, setOpen] = useState(false);

  const vertical = "top";
  const horizontal = "center";

  return (
    <div className="vacancy-form">
      <Snackbar
        anchorOrigin={{ vertical, horizontal }}
        open={open}
        autoHideDuration={3000}
        onClose={() => setOpen(false)}
      >
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            backgroundColor: '#BEF5BD',
            color: 'black',
            padding: '16px',
            borderRadius: '8px',
            textAlign: 'center',
          }}
        >
          <img
            src={success_icon}
            alt="Icon"
            style={{ marginRight: '8px', height: '22px', width: '22px' }}
          />
          Interview link copied
        </div>
      </Snackbar>
      <div className="success-step">
        <h2>Vacancy created!</h2>
        <p>Now you can copy and share the link</p>
        <a href={vacancyLink} target="_blank" rel="noopener noreferrer">{vacancyLink}</a>
        <div className="navigation-buttons">
          <button onClick={copyToClipboard}>Copy link</button>
          <button onClick={() => handleVacancyCreated(vacancyId)}>See vacancy</button>
         </div>
      </div>
    </div>
  );
};

export default SuccessStep;
