import React from 'react';
import kopi from '../../assets/kopi.png'; // Update this path accordingly

const BlueCircleIcon = () => (
  <svg
    width="16"
    height="16"
    style={{ marginRight: '10px', verticalAlign: 'middle' }}
    aria-hidden="true"
  >
    <circle cx="8" cy="8" r="8" fill="#FE7833" />
  </svg>
);

function Slide2() {
  const styles = {
    slide1Content: {
      textAlign: 'center',
      height: '95%',
      backgroundColor: '#FE78331A',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '20px',
      padding: '20px',
    },
    imageContainer: {
      position: 'relative',
      display: 'inline-block',
    },
    slide1TitleContainer: {
      position: 'absolute',
      top: '-101px',
      left: '-20px',
      textAlign: 'left',
      zIndex: 1,
    },
    slide1TitleKiwi: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      margin: 0,
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    slide1Subtitle: {
      color: '#3D3D3D',
      fontFamily: 'Lato, sans-serif', // Ensure 'Lato' font is loaded in your project
      fontSize: '36px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      margin: 0,
    },
    kiwiImage: {
      maxWidth: '600px',
      height: 'auto',
      marginTop: '100px',
    },
  };

  return (
    <div style={styles.slide1Content} className="mob-contain-slide2">
      <div style={styles.imageContainer}>
        <img src={kopi} alt="Kopi Bird" style={styles.kiwiImage} className="mob-img-slide2" />
        <div style={styles.slide1TitleContainer} className="mob-title-container slide2">
          <h3 style={styles.slide1TitleKiwi} className="mob-title-slide2">
            <BlueCircleIcon /> Kopi
          </h3>
          <h2 style={styles.slide1Subtitle} className="mob-subtitle-slide2">
            Create your Digital Self — a Virtual Twin that's Always in Sync with You!
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Slide2;