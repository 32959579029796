import React, { useState, useEffect, useRef } from 'react';
import CustomSnackbar from '../../../components/CustomSnackBar';

import './Vacancies.css';
import NewVacancy from './newVacancyFlow/NewVacancy';
import VacancyList from './VacancyList';
import VacancyDetails from './VacancyDetails';
import EditVacancyForm from './EditVacancyForm';
import apiConfig from '../../../config/apiConfig';
import { useLocation } from 'react-router-dom';
import success_icon from '../../../assets/BsCheck2.png';
import { getCookie } from '../../../components/cookieHelpers';

const API_GET_VACANCIES_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getVacancies}`;
const API_UPDATE_VACANCY_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.updateVacancy}`;
const API_DELETE_VACANCY_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.deleteVacancy}`;
const API_TOGGLE_VACANCY_ACTIVE = `${apiConfig.baseURL}${apiConfig.endpoints.toggleVacancyActive}`;
const API_GET_COMPANIES_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getCompanies}`;

const API_TOKEN = apiConfig.token;

function Vacancies() {
  const [showNewVacancy, setShowNewVacancy] = useState(false);
  const [step, setStep] = useState(1);
  const [vacancies, setVacancies] = useState([]);
  const [isLoadingVacancies, setIsLoadingVacancies] = useState(true);
  const [fetchError, setFetchError] = useState(null);
  const [dropdownVisible, setDropdownVisible] = useState(null);
  const [editingVacancy, setEditingVacancy] = useState(null);
  const [editedVacancy, setEditedVacancy] = useState({});
  const [selectedVacancy, setSelectedVacancy] = useState(null);
  const [vacancyQuestions, setVacancyQuestions] = useState([]);
  const [isSaving, setIsSaving] = useState(false);
  const [filter, setFilter] = useState('all');
  const [searchQuery, setSearchQuery] = useState('');
  const [open, setOpen] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);


  const dropdownRefs = useRef({});
  const location = useLocation();
  const containerRef = useRef(null);


  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    const token = getCookie('token');
    try {
      const response = await fetch(API_GET_COMPANIES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify({ pk: '*' }),
      });

      const data = await response.json();
      setCompanies(data.companies);
      if (data.companies && data.companies.length > 0) {
        setSelectedCompany(data.companies[0]);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  useEffect(() => {
    if (selectedVacancy && containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [selectedVacancy]);

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setOpen(false);
  };

  const handleCreateNew = () => {
    setShowNewVacancy(true);
  };

  const handleGoBack = () => {
    setShowNewVacancy(false);
    setStep(1); // Reset step when going back
  };

  const handleDropdownClick = (event, id) => {
    event.stopPropagation();
    setDropdownVisible(dropdownVisible === id ? null : id);
  };

  const handleClickOutside = (event) => {
    if (dropdownVisible && dropdownRefs.current[dropdownVisible] && !dropdownRefs.current[dropdownVisible].contains(event.target)) {
      setDropdownVisible(null);
    }
  };

  const handleVacancyClick = (vacancy) => {
    if (editingVacancy) {
      const shouldSwitch = window.confirm(
        "You're still editing. Are you sure you want to switch vacancies?"
      );
      if (!shouldSwitch) {
        return;
      }
      setEditingVacancy(null); // Cancel edit mode if switching
    }

    setSelectedVacancy(vacancy);
    setVacancyQuestions(vacancy.questions ? vacancy.questions.questions : []); // Set questions for the selected vacancy
    setDropdownVisible(null);
  };

  const copyLinkToClipboard = (id) => {
    const vacancyLink = `${apiConfig.baseURL}/interview?id=${id}`;
    navigator.clipboard.writeText(vacancyLink);
    setOpen(true);
  };

  const startEditingVacancy = (vacancy) => {
    setEditingVacancy(vacancy);  // Store the entire vacancy object
    setEditedVacancy({
      ...vacancy,
      description: vacancy.job_description,
    });
    setVacancyQuestions(vacancy.questions ? vacancy.questions.questions : []); // Set questions for the editing vacancy
    setDropdownVisible(null);
  };

  const handleEditChange = (e) => {
    const { name, value } = e.target;
    setEditedVacancy(prevState => ({ ...prevState, [name]: value }));
  };

  const handleSaveEdit = async () => {
    setIsSaving(true);
    console.log(editingVacancy.pk)
    const payload = {
      pk: editingVacancy.pk,  // Access the pk from the editingVacancy object
      job_title: editedVacancy.job_title,
      job_description: editedVacancy.description
    };
    const token = getCookie('token');
    try {
      const response = await fetch(API_UPDATE_VACANCY_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify(payload)
      });

      if (!response.ok) {
        throw new Error('Failed to edit vacancy');
      }

      // Check if active flag has changed
      if (editingVacancy.active !== editedVacancy.active) {
        await toggleVacancyActive(editingVacancy.pk);
      }

      alert('Vacancy details updated successfully!');
      await fetchVacancies();  // Refresh the vacancies list
      setEditingVacancy(null);
    } catch (error) {
      console.error('Error editing vacancy:', error);
      alert('Failed to update vacancy details. Please try again.');
    } finally {
      setIsSaving(false);
    }
  };

  const toggleVacancyActive = async (vacancyId) => {
    const token = getCookie('token');
    try {
      const response = await fetch(API_TOGGLE_VACANCY_ACTIVE, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify({ pk: vacancyId })
      });

      if (!response.ok) {
        throw new Error('Failed to update vacancy active status');
      }

      alert('Vacancy active status updated successfully!');
    } catch (error) {
      console.error('Error updating vacancy active status:', error);
      alert('Failed to update vacancy active status. Please try again.');
    }
  };

  const handleCancelEdit = () => {
    setEditingVacancy(null);
  };

  const handleDeleteVacancy = async (id) => {
    const token = getCookie('token');
    try {
      const response = await fetch(API_DELETE_VACANCY_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify({ pk: id })
      });

      if (!response.ok) {
        throw new Error('Failed to delete vacancy');
      }

      alert('Vacancy deleted successfully!');
      setVacancies(vacancies.filter(vacancy => vacancy.pk !== id));
      setEditingVacancy(null);
    } catch (error) {
      console.error('Error deleting vacancy:', error);
      alert('Failed to delete vacancy. Please try again.');
    }
  };

  const fetchVacancies = async (newPk = null) => {
    console.log("Fetching vacancies...");
    setIsLoadingVacancies(true);
    if (selectedVacancy) setSelectedVacancy(null);
    setVacancies([]);
    const token = getCookie('token');
    console.log('company', selectedCompany?.company?.name);
    try {
      const response = await fetch(API_GET_VACANCIES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN
        },
        body: JSON.stringify({ company: selectedCompany?.company?.name })
      });

      if (response.status === 404) {
        console.error('Vacancies not found');
        setIsLoadingVacancies(false);
        return;
      }
      
      const data = await response.json();
      console.log("Vacancies fetched successfully:", data);
      //Sort the vacancies. Newest first.
      data.vacancies.sort((a, b) => b.opened - a.opened);
      setVacancies(data.vacancies);
      if (newPk) {
        console.log(newPk);
        const foundVacancy = data.vacancies.find(v => v.pk === newPk);
        setSelectedVacancy(foundVacancy);
        console.log(foundVacancy);
        setStep(1);
        setShowNewVacancy(false);
      }
      setVacancyQuestions(data.vacancies?.[0]?.questions?.questions ?? []);
      setIsLoadingVacancies(false);
      setFetchError(null);
    } catch (error) {
      console.error('Error fetching vacancies:', error);
      setFetchError('Failed to fetch vacancies. Please try again.');
      setIsLoadingVacancies(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [dropdownVisible]);

  useEffect(() => {
    if (selectedCompany) {
      fetchVacancies();
    }
  }, [selectedCompany]);

  useEffect(() => {
    if (vacancies) {
      if (!selectedVacancy) setSelectedVacancy(vacancies[0]);
    }
  }, [vacancies]);

  const handleVacancyCreated = async (pk) => {
    fetchVacancies(pk);
  }


  const vertical = "top";
  const horizontal = "center";

  return (
    <div className="vacancies-page">
      <div>
        <CustomSnackbar
          open={open}
          onClose={handleClose}
          anchorOrigin={{ vertical, horizontal }}
          message="Interview link copied"
          backgroundColor="#BEF5BD"
          textColor="black"
          icon={success_icon}
          iconAlt="Success Icon"
        />
      </div>
      <VacancyList
        companies={companies}
        vacancies={vacancies}
        isLoadingVacancies={isLoadingVacancies}
        fetchError={fetchError}
        selectedVacancy={selectedVacancy}
        handleCreateNew={handleCreateNew}
        handleDropdownClick={handleDropdownClick}
        handleVacancyClick={handleVacancyClick}
        handleDeleteVacancy={handleDeleteVacancy}
        handleEditChange={handleEditChange}
        startEditingVacancy={startEditingVacancy}
        copyLinkToClipboard={copyLinkToClipboard}
        dropdownVisible={dropdownVisible}
        dropdownRefs={dropdownRefs}
        filter={filter}
        searchQuery={searchQuery}
        setFilter={setFilter}
        setSearchQuery={setSearchQuery}
        isNewVacancyShown={showNewVacancy}
        newVacancyStep={step}
        setNewVacancyStep={setStep}
        goBack={handleGoBack}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
      />

      <main className="vacancies-main">
        {showNewVacancy ? (
          <NewVacancy step={step} setStep={setStep} handleVacancyCreated={handleVacancyCreated} />
        ) : editingVacancy ? (
          <EditVacancyForm
            editedVacancy={editedVacancy}
            handleEditChange={handleEditChange}
            handleSaveEdit={handleSaveEdit}
            handleCancelEdit={handleCancelEdit}
            isSaving={isSaving}
            vacancyQuestions={vacancyQuestions}
          />
        ) : selectedVacancy ? (
          <VacancyDetails
            selectedVacancy={selectedVacancy}
            vacancyQuestions={vacancyQuestions}
            copyLinkToClipboard={copyLinkToClipboard}
            containerRef={containerRef}
          />
        ) : isLoadingVacancies ? (
          null
        ) : vacancies.length > 0 ? (
          <div className="no-data">
            <p>Nothing selected.</p>
            <p>Click on a vacancy to show its details.</p>
          </div>
        ) : (
          <div className="no-data">
            <h2>There is no data yet</h2>
            <p>Please create your first vacancy</p>
            <button className="no-data-btn" onClick={handleCreateNew}>Create vacancy</button>
          </div>
        )}
      </main>
    </div>
  );
}

export default Vacancies;
