// src/utils/Formatters.js

/**
 * Formats a duration given in seconds into a human-readable string.
 * @param {number} seconds - The duration in seconds.
 * @returns {string} The formatted duration string.
 */
export const formatDuration = (seconds) => {
    if (seconds === null) {
      return 'Unavailable';
    }
  
    const totalSeconds = Math.floor(seconds);
    const hours = Math.floor(totalSeconds / 3600);
    const minutes = Math.floor((totalSeconds % 3600) / 60);
    const remainingSeconds = totalSeconds % 60;
  
    if (hours > 0) {
      return `${hours} hour${hours > 1 ? 's' : ''} ${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else if (minutes > 0) {
      return `${minutes} minute${minutes > 1 ? 's' : ''}`;
    } else {
      return `${remainingSeconds} second${remainingSeconds > 1 ? 's' : ''}`;
    }
  };
  
