// hooks/useCreateCompany.js

import { useState } from 'react';
import { createCompany } from '../CompaniesService';

const useCreateCompany = () => {
  const [isCreating, setIsCreating] = useState(false);
  const [createError, setCreateError] = useState(null);

  const newCompany = async (payload) => {
    setIsCreating(true);
    setCreateError(null);
    try {
      const result = await createCompany(payload);
      return result;
    } catch (error) {
      setCreateError(error.message || 'Failed to create company');
      throw error;
    } finally {
      setIsCreating(false);
    }
  };

  return { isCreating, createError, newCompany };
};

export default useCreateCompany;