// src/hooks/useUpdateCandidate.js

import { useState } from 'react';
import { updateCandidate } from '../CandidateService';

const useUpdateCandidate = () => {
    const [isUpdating, setIsUpdating] = useState(false);
    const [updateError, setUpdateError] = useState(null);

    const handleUpdateCandidate = async (candidateData) => {
        setIsUpdating(true);
        setUpdateError(null);
        try {
            const result = await updateCandidate(candidateData);
            return result;
        } catch (error) {
            setUpdateError(error.message || 'Failed to update candidate');
            throw error;
        } finally {
            setIsUpdating(false);
        }
    };

    return { isUpdating, updateError, handleUpdateCandidate };
};

export default useUpdateCandidate;