import React, { useState, useEffect } from 'react';
import './CompanyDetails.css';
import { ReactComponent as EditIcon } from '../../../assets/EditIcon.svg'; // Import SVG as a React component
import MuiAlert from '@mui/material/Alert';

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const CompanyDetails = ({ company, setIsCreating, onEditCompany, products, services, containerRef }) => {

  if (!company) {
    return (
      <div className="no-data">
        <h2>There is no data yet</h2>
        <p>Please add your first company</p>
        <button className="no-data-btn" onClick={() => setIsCreating(true)}>Add a company</button>
      </div>
    );
  }

  return (
    <div className="company-details" ref={containerRef}>
      <div className="company-overview">
        <div className="company-details-header">
          <h3>Company Overview</h3>
          <div className="company-edit-button" onClick={() => onEditCompany(company)}>

            <EditIcon style={{ width: '16px', height: '16px', margin: '2px 0 0 0' }} />
            <p>Edit</p>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Company name</label>
            <input type="text" value={company.name} readOnly />
          </div>
          <div className="form-group">
            <label>Industry</label>
            <input type="text" value={company.industry} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Founded year</label>
            <input type="text" value={company.founded || '-'} readOnly />
          </div>
          <div className="form-group">
            <label>Number of employees</label>
            <input type="text" value={company.num_employees || '-'} readOnly />
          </div>
        </div>
        <div className="form-group">
          <label>Logo</label>
          <div className="logo-container">
            <img src={company.logo} alt="Company Logo" className="company-logo" />
          </div>
        </div>
        <div className="form-group">
          <label>Company mission</label>
          <pre className="non-editable-textarea"> {company.mission || '-'} </pre>
        </div>
        <div className="form-group">
          <label>Company vision</label>
          <pre className="non-editable-textarea"> {company.vision || '-'} </pre>
        </div>
        <div className="form-group">
          <label>Company description</label>
          <pre className="non-editable-textarea"> {company.description || '-'} </pre>
        </div>
      </div>

      <div className="company-values-and-culture card">
        <div className="company-details-header">
          <h3>Values & Culture</h3>
        </div>
        <div className="form-group">
          <label>Values</label>
          <pre className="non-editable-textarea">
            {company.values
              ? Object.entries(company.values)
                .map(([key, value]) => `• ${key}\n${value}`)
                .join('\n\n')
              : '-'}
          </pre>
        </div>

        <div className="form-group">
          <label>Culture</label>
          <pre className="non-editable-textarea"> {company.company_culture || '-'} </pre>
        </div>
        <div className="form-group">
          <label>Employee benefits & perks</label>
          <pre className="non-editable-textarea"> {company.benefits || '-'} </pre>
        </div>
      </div>

      <div className="company-business-portfolio card">
        <div className="company-details-header">
          <h3>Business Portfolio (optional)</h3>
        </div>

        {/* Products Section */}
        <div className="portfolio-section">
          <h4>Products</h4>
          {products?.length > 0 ? (
            products.map((productData, index) => (
              <div className="portfolio-item" key={index}>
                <h5>{productData?.product?.name}</h5>
                <p>{productData?.product?.description}</p>
                {/* Display features if they exist */}
                {productData?.product?.features && (
                  <ul>
                    {Object.entries(productData?.product?.features).map(([feature, description], i) => (
                      <li key={i}>
                        <strong>{feature}:</strong> {description}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
            ))
          ) : (
            <p>No products available.</p>
          )}
        </div>

        {/* Services Section */}
        <div className="portfolio-section">
          <h4>Services</h4>
          {services?.length > 0 ? (
            services.map((serviceData, index) => (
              <div className="portfolio-item" key={index}>
                <h5>{serviceData?.service?.name}</h5>
                <p>{serviceData?.service?.description}</p>
              </div>
            ))
          ) : (
            <p>No services available.</p>
          )}
        </div>

        {/* Achievements Section */}
        <div className="portfolio-section">
          <h4>Achievements</h4>
          <div className="portfolio-item">
            {company.achievements?.map((achievement, index) => (
              <p key={index}>• {achievement}<br /></p>
            )) || <p>-</p>}
          </div>
        </div>


        {/* Partnerships & Collaborations Section */}
        <div className="portfolio-section">
          <h4>Partnerships & collaborations</h4>
          <div className="portfolio-item">
            <h5>Collaborations</h5>
            {company.Partnerships?.map((partner, index) => (
              <p key={index}>{partner}</p>
            )) || <p>-</p>}
          </div>

        </div>
      </div>

      <div className="company-contact card">

        <div className="company-details-header">
          <h3>Contact</h3>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Company email</label>
            <input type="text" value={company.email} readOnly />
          </div>
          <div className="form-group">
            <label>Company website</label>
            <input type="text" value={company.website} readOnly />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Primary Location</label>
            <input type="text" value={company.headquarters} readOnly />
          </div>
          <div className="form-group">
            <label>Additional Location</label>
            <input type="text" value={company.other_addresses?.[0] || '-'} readOnly />
          </div>
        </div>
        <div className="company-details-header">
          <h3>Social media</h3>
        </div>
        {company?.social_media && Object.entries(company.social_media).map(([platform, url]) => (
          <div className="form-row" key={platform}>
            <div className="form-group">
              <input type="text" value={platform || ''} readOnly />
            </div>
            <div className="form-group">
              <input type="text" value={url || ''} readOnly />
            </div>
          </div>
        ))}

      </div>

    </div>
  );
};

export default CompanyDetails;
