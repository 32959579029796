// KodelabMenu.js
import React from 'react';
import { Menu, MenuItem } from '@mui/material';

const KodelabMenu = ({
  anchorEl,
  open,
  onClose,
  menuItems = [],
  imageComponent: ImageComponent,
  title,
  menuStyle = {},
  imageStyle = {},
  titleStyle = {},
  ...props
}) => {
  // Define padding constants
  const headerPadding = '20px 30px 5px 30px'; // Existing header padding
  const menuItemPaddingLeft = '16px';
  const menuItemPaddingRight = '30px';

  return (
    <Menu
      anchorEl={anchorEl}
      open={open}
      onClose={onClose}
      // Disable default padding on the MenuList
      MenuListProps={{ disablePadding: true }}
      // Customize the Paper (the menu container)
      PaperProps={{
        style: {
          minWidth: '200px',
          borderRadius: '12px', // Set border radius to 12px
          transform: 'translate(-20px, 5px)', // Adjust position
          ...menuStyle,
        },
      }}
      // Keep the default anchor and transform origins
      anchorOrigin={{
        vertical: 'bottom',
        horizontal: 'right',
      }}
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      {...props}
    >
      {/* Content Wrapper without padding */}
      <div style={{ boxSizing: 'border-box' }}>
        {/* Header Section with padding */}
        {(ImageComponent || title) && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: headerPadding, // Use headerPadding variable
            }}
          >
            {/* Image Component */}
            {ImageComponent && (
              <div style={{ marginRight: '10px', ...imageStyle }}>
                {React.isValidElement(ImageComponent) ? (
                  React.cloneElement(ImageComponent, {
                    style: {
                      width: '40px',
                      height: '40px',
                      ...ImageComponent.props.style,
                    },
                  })
                ) : (
                  <ImageComponent
                    style={{
                      width: '40px',
                      height: '40px',
                    }}
                  />
                )}
              </div>
            )}
            {/* Title */}
            {title && (
              <div style={{ ...titleStyle }}>
                <h3 style={{ margin: 0, paddingBottom:'10px' }}>{title}</h3>
              </div>
            )}
          </div>
        )}
        {/* Menu Items with Dividers */}
        {menuItems.map((item, index) => (
          <React.Fragment key={index}>
            <MenuItem
              onClick={() => {
                item.onClick();
                onClose();
              }}
              style={{ minHeight: '48px' }} // Ensure consistent height
            >
              {/* Wrap the label in a div with padding and text styles */}
              <div
                style={{
                  paddingLeft: menuItemPaddingLeft,
                  paddingRight: menuItemPaddingRight,
                  fontSize: '16px',
                  fontStyle: 'normal',
                  fontWeight: 400,
                  lineHeight: '22px',
                }}
              >
                {item.label}
              </div>
            </MenuItem>
            {/* Divider between items with horizontal padding, except after the last item */}
            {index < menuItems.length - 1 && (
              <div
                style={{
                  height: '1px',
                  background: 'var(--light-grey-stroke, #EBEBEB)',
                  marginLeft: menuItemPaddingLeft,
                  marginRight: menuItemPaddingRight,
                }}
              />
            )}
          </React.Fragment>
        ))}
      </div>
    </Menu>
  );
};

export default KodelabMenu;