// src/hooks/useCandidateEvaluation.js
import { useState, useEffect } from 'react';
import { getEvaluationDetails } from '../CandidateService';

const useCandidateEvaluation = (candidatePk, companyPk) => {
    const [evaluationData, setEvaluationData] = useState(null);
    const [isLoadingEvaluation, setIsLoadingEvaluation] = useState(true);
    const [evaluationError, setEvaluationError] = useState(null);

    useEffect(() => {
        if (!candidatePk) return;

        const fetchData = async () => {
            try {
                const data = await getEvaluationDetails(candidatePk, companyPk);
                setEvaluationData(data);
            } catch (error) {
                setEvaluationError('Failed to fetch candidate evaluation details.');
            } finally {
                setIsLoadingEvaluation(false);
            }
        };

        fetchData();
    }, [candidatePk]);

    return { evaluationData, isLoadingEvaluation, evaluationError };
};

export default useCandidateEvaluation;