// src/components/ProtectedRoute.js
import React from 'react';
import { Navigate } from 'react-router-dom';

const ProtectedRoute = ({ isAuthenticated, isLoading, children }) => {
  if (isLoading) {
    return null;
  }
  return isAuthenticated ? children : <Navigate to="/" />;
};

export default ProtectedRoute;
