import React, { useState } from 'react';
import industryOptions from './data/industryOptions';

import apiConfig from '../../../config/apiConfig';
import { getCookie } from '../../../components/cookieHelpers';

import { CircularProgress } from '@mui/material';

import './EditCompanyForm.css';

const ADD_PRODUCT_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.addProduct}`;
const ADD_SERVICE_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.addService}`;
const REMOVE_PRODUCT_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.removeProduct}`;
const REMOVE_SERVICE_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.removeService}`;
const UPDATE_COMPANY_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.updateCompany}`;

const API_TOKEN = apiConfig.token;
const EditCompanyForm = ({ company, onEditCompany, onSaveChanges, products: initialProducts, services: initialServices }) => {
  const [editableCompany, setEditableCompany] = useState({ ...company });
  const [products, setProducts] = useState(initialProducts || []);
  const [services, setServices] = useState(initialServices || []);
  const [achievements, setAchievements] = useState(company.achievements || []);
  const [values, setValues] = useState(Object.entries(company.values || {}));
  const [partnerships, setPartnerships] = useState(company.Partnerships || []);
  const [additionalLocations, setAdditionalLocations] = useState(company.other_addresses || []);
  const [socialMedias, setSocialMedias] = useState(editableCompany.social_media || {});
  const [benefits, setBenefits] = useState(Array.isArray(company.benefits) ? company.benefits : []);

  const [productDetails, setProductDetails] = useState({ name: '', description: '' });
  const [serviceDetails, setServiceDetails] = useState({ name: '', description: '' });
  const [newAchievement, setNewAchievement] = useState('');
  const [newValue, setNewValue] = useState({ key: '', value: '' });
  const [newPartnership, setNewPartnership] = useState('');
  const [newLocation, setNewLocation] = useState('');
  const [newSocialMedia, setNewSocialMedia] = useState({ platform: '', url: '' });
  const [newBenefit, setNewBenefit] = useState({ title: '', description: '' });

  const [isLoading, setIsLoading] = useState(false);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setEditableCompany({ ...editableCompany, [name]: value });
  };

  const handleProductChange = (e) => {
    const { name, value } = e.target;
    setProductDetails({
      ...productDetails,
      [name]: value,
    });
  };

  const handleServiceChange = (e) => {
    const { name, value } = e.target;
    setServiceDetails({
      ...serviceDetails,
      [name]: value,
    });
  };

  const addProduct = () => {
    if (productDetails.name && productDetails.description) {
      setProducts([...products, { product: productDetails }]);
      setProductDetails({ name: '', description: '' });
    }
  };

  const removeProduct = (index) => {
    const updatedProducts = products.filter((_, i) => i !== index);
    setProducts(updatedProducts);
  };

  const addService = () => {
    if (serviceDetails.name && serviceDetails.description) {
      setServices([...services, { service: serviceDetails }]);
      setServiceDetails({ name: '', description: '' });
    }
  };

  const removeService = (index) => {
    const updatedServices = services.filter((_, i) => i !== index);
    setServices(updatedServices);
  };

  const addAchievement = () => {
    if (newAchievement.trim()) {
      setAchievements([...achievements, newAchievement.trim()]);
      setNewAchievement('');
    }
  };

  const removeAchievement = (index) => {
    const updatedAchievements = achievements.filter((_, i) => i !== index);
    setAchievements(updatedAchievements);
  };

  const addValue = () => {
    if (newValue.key.trim() && newValue.value.trim()) {
      setValues([...values, [newValue.key.trim(), newValue.value.trim()]]);
      setNewValue({ key: '', value: '' });
    }
  };

  const handleValueChange = (index, key, value) => {
    const updatedValues = values.map((val, i) => (i === index ? [key, value] : val));
    setValues(updatedValues);
  };

  const removeValue = (index) => {
    const updatedValues = values.filter((_, i) => i !== index);
    setValues(updatedValues);
  };

  const addPartnership = () => {
    if (newPartnership.trim()) {
      setPartnerships([...partnerships, newPartnership.trim()]);
      setNewPartnership('');
    }
  };

  const removePartnership = (index) => {
    const updatedPartnerships = partnerships.filter((_, i) => i !== index);
    setPartnerships(updatedPartnerships);
  };

  const addLocation = () => {
    if (newLocation.trim()) {
      setAdditionalLocations([...additionalLocations, newLocation.trim()]);
      setNewLocation('');
    }
  };

  const removeLocation = (index) => {
    const updatedLocations = additionalLocations.filter((_, i) => i !== index);
    setAdditionalLocations(updatedLocations);
  };

  const handleSocialMediaChange = (e) => {
    const { name, value } = e.target;
    setNewSocialMedia({
      ...newSocialMedia,
      [name]: value,
    });
  };

  const addSocialMedia = () => {
    if (newSocialMedia.platform && newSocialMedia.url) {
      setSocialMedias({ ...socialMedias, [newSocialMedia.platform]: newSocialMedia.url });
      setNewSocialMedia({ platform: '', url: '' });
    }
  };

  const removeSocialMedia = (platform) => {
    const updatedSocialMedias = { ...socialMedias };
    delete updatedSocialMedias[platform];
    setSocialMedias(updatedSocialMedias);
  };

  const handleBenefitChange = (index, field, value) => {
    const updatedBenefits = benefits.map((benefit, i) =>
      i === index ? { ...benefit, [field]: value } : benefit
    );
    setBenefits(updatedBenefits);
  };

  const addBenefit = () => {
    if (newBenefit.title.trim() && newBenefit.description.trim()) {
      setBenefits([...benefits, { ...newBenefit }]);
      setNewBenefit({ title: '', description: '' });
    }
  };

  const removeBenefit = (index) => {
    const updatedBenefits = benefits.filter((_, i) => i !== index);
    setBenefits(updatedBenefits);
  };

  const processAdditionsAndRemovals = (
    initialItems,
    currentItems,
    itemKey,
    companyPk,
    addEndpoint,
    removeEndpoint,
    setItems
  ) => {
    const token = getCookie('token');

    const currentItemPks = currentItems
      .filter((item) => item[itemKey].pk)
      .map((item) => item[itemKey].pk);

    const itemsToRemove = initialItems.filter(
      (item) => !currentItemPks.includes(item[itemKey].pk)
    );

    const itemsToAdd = currentItems.filter((item) => !item[itemKey].pk);

    const fetchPromises = []; // **Collect fetch promises**

    // Remove items
    itemsToRemove.forEach((item) => {
      const data = {
        company_pk: companyPk,
        [`${itemKey}_pk`]: item[itemKey].pk,
      };
      const fetchPromise = fetch(removeEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((result) => {
          console.log(`${itemKey} removed:`, result);
        })
        .catch((error) => {
          console.error(`Error removing ${itemKey}:`, error);
        });

      fetchPromises.push(fetchPromise);
    });

    // Add new items
    itemsToAdd.forEach((item) => {
      const data = {
        pk: companyPk,
        [itemKey]: item[itemKey],
      };
      const fetchPromise = fetch(addEndpoint, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          Authorization: `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify(data),
      })
        .then((response) => response.json())
        .then((result) => {
          setItems((prevItems) =>
            prevItems.map((i) => (i === item ? { [itemKey]: result[itemKey] } : i))
          );
          console.log(`${itemKey} added:`, result);
        })
        .catch((error) => {
          console.error(`Error adding ${itemKey}:`, error);
        });

      fetchPromises.push(fetchPromise);
    });

    // **Return a Promise that resolves when all fetches are complete**
    return Promise.all(fetchPromises);
  };

  const updateCompany = (
    companyPk,
    editableCompany,
    values,
    socialMedias,
    UPDATE_COMPANY_ENDPOINT,
    API_TOKEN
  ) => {
    const token = getCookie('token'); // Retrieve the token for API authentication

    // Create the payload for the company update
    const companyUpdatePayload = {
      pk: companyPk,
      name: editableCompany.name || null,
      vision: editableCompany.vision || null,
      mission: editableCompany.mission || null,
      description: editableCompany.description || null,
      company_culture: editableCompany.company_culture || null,
      values:
        values.length > 0
          ? values.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
          : null,
      industry: editableCompany.industry || null,
      founded: editableCompany.founded || null,
      num_employees: parseInt(editableCompany.num_employees) || null,
      headquarters: editableCompany.headquarters || null,
      website: editableCompany.website || null,
      email: editableCompany.email || null,
      phone: editableCompany.phone || null,
      address: editableCompany.address || null,
      social_media:
        Object.keys(socialMedias).length > 0 ? socialMedias : null,
    };

    console.log('Company update payload:', companyUpdatePayload);

    // Return the fetch promise
    return fetch(UPDATE_COMPANY_ENDPOINT, {
      method: 'POST', // Adjust method if needed
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
        'api-key': API_TOKEN,
      },
      body: JSON.stringify(companyUpdatePayload),
    })
      .then((response) => response.json())
      .then((result) => {
        console.log('Company updated:', result);
        return result.company;
      })
      .catch((error) => {
        console.error('Error updating company:', error);
        throw error; // Re-throw to catch in saveChanges
      });
  };

  const saveChanges = () => {
    setIsLoading(true); // Set loading to true when save starts

    const productPromise = processAdditionsAndRemovals(
      initialProducts,
      products,
      'product',
      company.pk,
      ADD_PRODUCT_ENDPOINT,
      REMOVE_PRODUCT_ENDPOINT,
      setProducts
    );

    const servicePromise = processAdditionsAndRemovals(
      initialServices,
      services,
      'service',
      company.pk,
      ADD_SERVICE_ENDPOINT,
      REMOVE_SERVICE_ENDPOINT,
      setServices
    );

    // Call updateCompany and get the promise
    const updateCompanyPromise = updateCompany(
      company.pk,
      editableCompany,
      values,
      socialMedias,
      UPDATE_COMPANY_ENDPOINT,
      API_TOKEN
    );

    Promise.all([productPromise, servicePromise, updateCompanyPromise])
      .then(([productResults, serviceResults, updatedCompany]) => {
        setIsLoading(false);
        onSaveChanges(updatedCompany);
      })
      .catch((error) => {
        console.error('Error saving changes:', error);
        setIsLoading(false);
      });
  };

  return (
    <div className="company-details">
      <div className="company-overview card">
        <div className="company-details-header">
          <h3>Company Overview</h3>
          <div className="company-edit-button" onClick={() => onEditCompany(null)}>
            <p>Cancel</p>
          </div>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Company name</label>
            <input className="company-overview-enabled" type="text" name="name" value={editableCompany.name} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Industry</label>
            <select
              name="industry"
              value={editableCompany.industry || ''}
              onChange={(e) => setEditableCompany({ ...editableCompany, industry: e.target.value })}
              className="company-overview-enabled"
            >
              <option value="">Select Industry</option>
              {industryOptions.map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </select>
          </div>

        </div>
        <div className="form-row">
          <div className="form-group">
            <label>Founded year</label>
            <input
              type="date"
              name="founded"
              value={editableCompany.founded || ''}
              onChange={handleInputChange}
            />
          </div>
          <div className="form-group">
            <label>Number of employees</label>
            <select
              name="num_employees"
              value={editableCompany.num_employees || ''}
              onChange={handleInputChange}
            >
              <option value="">Select number of employees</option>
              <option value="1">1-10</option>
              <option value="11">11-50</option>
              <option value="51">51-200</option>
              <option value="201">201-500</option>
              <option value="501">501-1,000</option>
              <option value="1001">1,001-5,000</option>
              <option value="5001">5,001-10,000</option>
              <option value="10001">10,001+</option>
            </select>
          </div>
        </div>
        <div className="form-group">
          <label>Logo</label>
          <div className="logo-container">
            <img src={editableCompany.logo} alt="Company Logo" className="company-logo" />
          </div>
        </div>
        <div className="form-group">
          <label>Company mission</label>
          <textarea
            name="mission"
            value={editableCompany.mission || ''}
            onChange={handleInputChange}
            className="non-editable-textarea"
          ></textarea>
        </div>
        <div className="form-group">
          <label>Company vision</label>
          <textarea
            name="vision"
            value={editableCompany.vision || ''}
            onChange={handleInputChange}
            className="non-editable-textarea"
          ></textarea>
        </div>
        <div className="form-group">
          <label>Company description</label>
          <textarea
            name="description"
            value={editableCompany.description || ''}
            onChange={handleInputChange}
            className="non-editable-textarea"
          ></textarea>
        </div>
      </div>

      <div className="company-values-and-culture card">
        <div className="company-details-header">
          <h3>Values & Culture</h3>
        </div>
        {/* Values Section */}
        <div className="portfolio-section">
          <h4>Add a new value</h4>
          {/* Input for new value */}
          <div className="form-group key-value">
            <input
              type="text"
              placeholder="Enter Value Name"
              value={newValue.key}
              onChange={(e) => setNewValue({ ...newValue, key: e.target.value })}
            />
            <textarea
              placeholder="Enter Value Description"
              value={newValue.value}
              onChange={(e) => setNewValue({ ...newValue, value: e.target.value })}
            />
            <button type="button" onClick={addValue}>
              Add Value
            </button>
          </div>

          <h4>Values</h4>
          {/* List of Added Values */}
          <ul className="value-list">
            {values.map(([key, value], index) => (
              <li key={index} className="value-item">
                <strong>{key}</strong>: {value}
                <button type="button" onClick={() => removeValue(index)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>

        </div>

        {/* Benefits & Perks Section */}
        <div className="portfolio-section">
          <h4>Employee Benefits & Perks</h4>
          {/* Input for new benefit */}
          <div className="form-group key-value">
            <input
              type="text"
              placeholder="Enter Benefit Name..."
              value={newBenefit.title}
              onChange={(e) => setNewBenefit({ ...newBenefit, title: e.target.value })}
            />
            <textarea
              placeholder="Enter Benfit Description"
              value={newBenefit.description}
              onChange={(e) => setNewBenefit({ ...newBenefit, description: e.target.value })}
            />

            <button type="button" onClick={addBenefit} className="toggle-button">
              Add Benefit
            </button>
          </div>
          {/* List of Added Benefits */}
          <ul className="benefit-list">
            {benefits.map((benefit, index) => (
              <li key={index} className="benefit-item">
                <strong>{benefit.title}</strong>: {benefit.description}
                <button type="button" onClick={() => removeBenefit(index)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>

        </div>

        <div className="form-group">
          <label>Culture</label>
          <textarea
            name="company_culture"
            value={editableCompany.company_culture || ''}
            onChange={handleInputChange}
            className="non-editable-textarea"
          ></textarea>
        </div>
      </div>

      <div className="company-business-portfolio card">
        <div className="company-details-header">
          <h3>Business Portfolio</h3>
        </div>

        {/* Products Section */}
        <div className="portfolio-section">
          <h4>Products</h4>
          <div className="form-group key-value">
            <input
              type="text"
              name="name"
              value={productDetails.name}
              onChange={handleProductChange}
              placeholder="Enter product name..."
            />
            <textarea
              name="description"
              placeholder="Enter product details..."
              className="editable-textarea"
              value={productDetails.description}
              onChange={handleProductChange}
            ></textarea>
            <button type="button" onClick={addProduct} className="toggle-button">
              Add Product
            </button>
          </div>


          {/* List of Added Products */}
          <ul className="product-list">
            {products.map((productData, index) => (
              <li key={index} className="product-item">
                <strong>{productData?.product?.name}</strong>: {productData?.product?.description}
                <button type="button" onClick={() => removeProduct(index)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Services Section */}
        <div className="portfolio-section">
          <h4>Services</h4>
          <div className="form-group key-value">
            <input
              type="text"
              name="name"
              value={serviceDetails.name}
              onChange={handleServiceChange}
              placeholder="Enter service name..."
            />
            <textarea
              name="description"
              placeholder="Enter service details..."
              className="editable-textarea"
              value={serviceDetails.description}
              onChange={handleServiceChange}
            ></textarea>
            <button type="button" onClick={addService} className="toggle-button">
              Add Service
            </button>
          </div>

          {/* List of Added Services */}
          <ul className="service-list">
            {services.map((serviceData, index) => (
              <li key={index} className="service-item">
                <strong>{serviceData?.service?.name}</strong>: {serviceData?.service?.description}
                <button type="button" onClick={() => removeService(index)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Achievements Section */}
        <div className="portfolio-section">
          <h4>Achievements</h4>
          <div className="form-group">
            <input
              type="text"
              value={newAchievement}
              onChange={(e) => setNewAchievement(e.target.value)}
              placeholder="Enter a new achievement..."
            />
            <button type="button" onClick={addAchievement} className="add-achievement-btn">
              Add Achievement
            </button>
          </div>

          {/* List of Added Achievements */}
          <ul className="achievement-list">
            {achievements.map((achievement, index) => (
              <li key={index} className="achievement-item">
                {achievement}
                <button type="button" onClick={() => removeAchievement(index)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>

        {/* Partnerships Section */}
        <div className="portfolio-section">
          <h4>Partnerships & Collaborations</h4>
          <div className="form-group">
            <input
              type="text"
              value={newPartnership}
              onChange={(e) => setNewPartnership(e.target.value)}
              placeholder="Enter a new partnership..."
            />
            <button type="button" onClick={addPartnership} className="add-achievement-btn">
              Add Partnership
            </button>
          </div>
          {/* List of Added Partnerships */}
          <ul className="partnership-list">
            {partnerships.map((partnership, index) => (
              <li key={index} className="partnership-item">
                {partnership}
                <button type="button" onClick={() => removePartnership(index)}>
                  Remove
                </button>
              </li>
            ))}
          </ul>
        </div>
      </div>

      <div className="company-contact card">
        <div className="company-details-header">
          <h3>Contact</h3>
        </div>

        <div className="form-row">
          <div className="form-group">
            <label>Company email</label>
            <input type="text" name="email" value={editableCompany.email} onChange={handleInputChange} />
          </div>
          <div className="form-group">
            <label>Company website</label>
            <input type="text" name="website" value={editableCompany.website} onChange={handleInputChange} />
          </div>
        </div>
        <div className="form-group">
          <label>Primary Location</label>
          <input
            type="text"
            name="headquarters"
            value={editableCompany.headquarters}
            onChange={handleInputChange}
          />
        </div>
        {/* Additional Locations Section */}
        <div className="form-row">
          <div className="form-group">
            <label>Additional Locations</label>
            <div className="form-group">
              <input
                type="text"
                value={newLocation}
                onChange={(e) => setNewLocation(e.target.value)}
                placeholder="Enter a new location..."
              />
              <button type="button" onClick={addLocation} className="add-achievement-btn">
                Add Location
              </button>
            </div>

            {/* List of Added Additional Locations */}
            <ul className="location-list">
              {additionalLocations.map((location, index) => (
                <li key={index} className="location-item">
                  {location}
                  <button type="button" onClick={() => removeLocation(index)}>
                    Remove
                  </button>
                </li>
              ))}
            </ul>
          </div>
        </div>


        <div className="company-details-header">
          <h3>Social media</h3>
        </div>
        <div className="form-row">
          <div className="form-group">
            <select
              name="platform"
              value={newSocialMedia.platform}
              onChange={handleSocialMediaChange}
            >
              <option value="">Select Platform</option>
              <option value="Discord">Discord</option>
              <option value="Facebook">Facebook</option>
              <option value="Instagram">Instagram</option>
              <option value="LinkedIn">LinkedIn</option>
              <option value="Pinterest">Pinterest</option>
              <option value="Reddit">Reddit</option>
              <option value="Telegram">Telegram</option>
              <option value="TikTok">TikTok</option>
              <option value="Twitter">Twitter</option>
              <option value="YouTube">YouTube</option>
            </select>
          </div>
          <div className="form-group">
            <input
              type="url"
              name="url"
              value={newSocialMedia.url}
              onChange={handleSocialMediaChange}
              placeholder="Enter URL..."
            />
          </div>
        </div>
        <button type="button" onClick={addSocialMedia} className="add-socials-btn">
          Add Social Media
        </button>

        {/* List of Added Social Media */}
        <ul className="social-media-list">
          {Object.entries(socialMedias).map(([platform, url]) => (
            <li key={platform} className="social-media-item">
              <strong>{platform}</strong>: {url}
              <button type="button" onClick={() => removeSocialMedia(platform)}>
                Remove
              </button>
            </li>
          ))}
        </ul>
      </div>

      {/* Save Changes Button */}
      <div className="form-actions">
        <button type="button" onClick={saveChanges} className="save-button">
          Save Changes
        </button>
      </div>

      {isLoading && (
        <div className="loading-overlay">
          <CircularProgress />
        </div>
      )}
    </div>
  );
};

export default EditCompanyForm;
