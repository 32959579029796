import React from 'react';
import DownArrowIcon from '../assets/DownArrowIcon.svg';

const KodelabSelect = ({
    label,
    placeholder = 'Select an option',
    style = {},
    inputStyle = {},
    labelStyle = {},
    options = [],
    id,
    multiple = false,
    className = '',
    value,
    required = false,
    ...props
}) => {
    const isPlaceholder = value === '';

    const defaultSelectStyle = {
        boxSizing: 'border-box',
        width: '100%',
        height: multiple ? '100px' : '54px',
        padding: '15px 38px 15px 15px',
        margin: '1px',
        marginBottom: '10px',
        border: '1px solid #EBEBEB',
        borderRadius: '8px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22px',
        appearance: 'none',
        backgroundColor: '#fff',
        backgroundImage: `url(${DownArrowIcon})`, 
        backgroundRepeat: 'no-repeat',
        backgroundPosition: 'right 15px center',
        backgroundSize: '18px 18px',
        cursor: 'pointer',
        color: isPlaceholder ? '#636c72' : '#202020',
        ...inputStyle,
    };

    const defaultLabelStyle = {
        display: 'block',
        marginBottom: '2px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22px',
        ...labelStyle,
    };

    return (
        <div style={style}>
            {label && (
                <label style={defaultLabelStyle} htmlFor={id}>
                    {label}
                    {required && <span> *</span>}
                </label>
            )}
            <select
                id={id}
                style={defaultSelectStyle}
                multiple={multiple}
                className={className}
                value={value} // Controlled select
                {...props}
            >
                {!multiple && (
                    <option value="" disabled hidden>
                        {placeholder}
                    </option>
                )}
                {options.map((option) => (
                    <option key={option.value} value={option.value}>
                        {option.label}
                    </option>
                ))}
            </select>
        </div>
    );
};

export default KodelabSelect;