import React from 'react';
import { Radar } from 'react-chartjs-2';
import { Chart as ChartJS, RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend } from 'chart.js';

ChartJS.register(RadialLinearScale, PointElement, LineElement, Filler, Tooltip, Legend);

const RadarChartPlaceholder = ({ labels, data }) => {
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Skill Levels',
        data: data,
        backgroundColor: 'rgba(34, 202, 236, 0.2)',
        borderColor: 'rgba(34, 202, 236, 1)',
        borderWidth: 2,
      },
    ],
  };

  const options = {
    maintainAspectRatio: false,
    responsive: true,
    scale: {
      min: 0,
      max: 100,
      ticks: { max: 100 },
    },
    plugins: {
      legend: {
        display: false
      }
    }
  };


  return (
    <div className="radar-chart-container">
      <Radar data={chartData} options={options} />
    </div>
  );
};

export default RadarChartPlaceholder;
