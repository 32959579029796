// hooks/useUpdateCompany.js

import { useState } from 'react';
import { updateCompany } from '../CompaniesService';

const useUpdateCompany = () => {
  const [isUpdating, setIsUpdating] = useState(false);
  const [updateError, setUpdateError] = useState(null);

  const handleUpdateCompany = async (updatedCompanyData) => {
    setIsUpdating(true);
    setUpdateError(null);
    try {
      const result = await updateCompany(updatedCompanyData);
      return result;
    } catch (error) {
      setUpdateError(error.message || 'Failed to update company');
      throw error;
    } finally {
      setIsUpdating(false);
    }
  };

  return { isUpdating, updateError, handleUpdateCompany };
};

export default useUpdateCompany;