import React from 'react';
import { styled } from '@mui/system';
import ButtonBase from '@mui/material/ButtonBase';
import { ReactComponent as BackArrowIcon } from '../assets/BackArrowIcon.svg'; 

const KodelabCircularBackButton = ({
  onClick,
  size = '54px', 
  style = {},
  ...props
}) => {
  const CircularButton = styled(ButtonBase)({
    backgroundColor: '#FFFFFF', 
    color: '#3399FF', 
    border: '2px solid #3399FF', 
    borderRadius: '50%',
    width: size, 
    height: size, 
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    cursor: 'pointer',
    outline: 'none',
    transition: 'background-color 0.3s ease',
    '&:hover': {
        border: '2px solid #127be3', 
    },
    '&:active': {// Darker blue background when clicked
    },
    ...style, 
  });

  return (
    <CircularButton onClick={onClick} disableRipple={false} {...props}>
      <BackArrowIcon style={{ width: '24px', height: '24px' }} /> {/* Arrow icon */}
    </CircularButton>
  );
};

export default KodelabCircularBackButton;