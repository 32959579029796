import React, { useState, useRef, useEffect } from 'react';
import CompanyOverview from './steps/CompanyOverview';
import Contact from './steps/Contact';
import ValuesCulture from './steps/ValuesCulture';
import BusinessPortfolio from './steps/BusinessPortfolio';
import CustomSnackbar from '../../../../components/CustomSnackBar';
import { CircularProgress } from '@mui/material';

import apiConfig from '../../../../config/apiConfig';
import { getCookie } from '../../../../components/cookieHelpers';

const ADD_PRODUCT_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.addProduct}`;
const ADD_SERVICE_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.addService}`;
const REMOVE_PRODUCT_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.removeProduct}`;
const REMOVE_SERVICE_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.removeService}`;
const UPDATE_COMPANY_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.updateCompany}`;
const API_TOKEN = apiConfig.token;

const NewCompany = ({ step, setStep, onCreateCompany, existingCompany = null, mode = 'create', onSaveChanges, initialProducts, initialServices }) => {

    const [formState, setFormState] = useState(() => ({
        name: existingCompany?.name || '',
        description: existingCompany?.description || '',
        vision: existingCompany?.vision || '',
        mission: existingCompany?.mission || '',
        company_culture: existingCompany?.company_culture || '',
        benefits_perks: existingCompany?.benefits_perks || '',
        industry: existingCompany?.industry || '',
        founded: existingCompany?.founded || '',
        num_employees: existingCompany?.num_employees || '',
        headquarters: existingCompany?.headquarters || '',
        website: existingCompany?.website || '',
        email: existingCompany?.email || '',
        phone: existingCompany?.phone || '',
    }));

    const requiredFields = [
        'name',
        'industry',
        'mission',
        'vision',
        'description',
    ]

    const [values, setValues] = useState(existingCompany?.values || {});
    const [newValue, setNewValue] = useState({ name: '', tagline: '' });

    const [errors, setErrors] = useState({});

    const [products, setProducts] = useState(initialProducts || []);
    const [services, setServices] = useState(initialServices || []);
    const [productDetails, setProductDetails] = useState({ name: '', description: '' });
    const [serviceDetails, setServiceDetails] = useState({ name: '', description: '' });
    const [otherAddresses, setOtherAddresses] = useState(existingCompany?.other_addresses || []);
    const [newAddress, setNewAddress] = useState('');
    const [newSocialMedia, setNewSocialMedia] = useState({ platform: '', url: '' });
    const [socialMedias, setSocialMedias] = useState(existingCompany?.social_media || {});
    const [snackbarOpen, setSnackbarOpen] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);

    const containerRef = useRef(null);


    useEffect(() => {
        if (containerRef.current) {
            containerRef.current.scrollTo({
                top: 0,
                behavior: 'smooth'
            });
        }
    }, [errors]);

    const handleNextStep = async (firstStep = false) => {
        let errors = inputValidation();
        if (firstStep && (Object.keys(errors).length > 0)) {
            setErrors(errors);
            setSnackbarMessage('Fill out required fields.');
            setSnackbarOpen(true);
        } else { setStep(step + 1) }
    };

    const handlePreviousStep = () => {
        setStep(step - 1);
    };

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormState({
            ...formState,
            [name]: name === 'num_employees' ? parseInt(value) : value,
        });
    };

    const handleValueChange = (e) => {
        const { name, value } = e.target;
        setNewValue({
            ...newValue,
            [name]: value,
        });
    };

    const addValue = () => {
        if (newValue.name && newValue.tagline) {
            setValues({ ...values, [newValue.name]: newValue.tagline });
            setNewValue({ name: '', tagline: '' });
        }
    };

    const removeValue = (name) => {
        const updatedValues = { ...values };
        delete updatedValues[name];
        setValues(updatedValues);
    };

    const handleProductChange = (e) => {
        const { name, value } = e.target;
        setProductDetails({
            ...productDetails,
            [name]: value,
        });
    };

    const handleServiceChange = (e) => {
        const { name, value } = e.target;
        setServiceDetails({
            ...serviceDetails,
            [name]: value,
        });
    };

    const addProduct = () => {
        if (productDetails.name && productDetails.description) {
            setProducts([...products, { product: productDetails }]);
            setProductDetails({ name: '', description: '' }); // Reset inputs after adding
        }
    };

    const removeProduct = (index) => {
        const updatedProducts = products.filter((_, i) => i !== index);
        setProducts(updatedProducts);
    };

    const addService = () => {
        if (serviceDetails.name && serviceDetails.description) {
            setServices([...services, { service: serviceDetails }]);
            setServiceDetails({ name: '', description: '' }); // Reset inputs after adding
        }
    };

    const removeService = (index) => {
        const updatedServices = services.filter((_, i) => i !== index);
        setServices(updatedServices);
    };

    const addAddress = () => {
        if (newAddress) {
            setOtherAddresses([...otherAddresses, newAddress]);
            setNewAddress(''); // Reset input after adding
        }
    };

    const removeAddress = (index) => {
        const updatedAddresses = otherAddresses.filter((_, i) => i !== index);
        setOtherAddresses(updatedAddresses);
    };

    const addSocialMedia = () => {
        if (newSocialMedia.platform && newSocialMedia.url) {
            setSocialMedias({ ...socialMedias, [newSocialMedia.platform]: newSocialMedia.url }); // Updated to object format
            setNewSocialMedia({ platform: '', url: '' }); // Reset inputs after adding
        }
    };

    const removeSocialMedia = (platform) => {
        const updatedSocialMedias = { ...socialMedias };
        delete updatedSocialMedias[platform];
        setSocialMedias(updatedSocialMedias);
    };

    const handleSocialMediaChange = (e) => {
        const { name, value } = e.target;
        setNewSocialMedia({
            ...newSocialMedia,
            [name]: value,
        });
    };

    const ensureURLScheme = (url) => {
        if (!url) return null; // Return null if the URL is empty or not provided
        return url.startsWith("http://") || url.startsWith("https://")
            ? url
            : `https://${url}`; // Add 'https://' if no scheme is present
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (mode == 'create') {
            console.log('products', products);
            console.log('services', services);

            const payload = {
                name: formState.name?.trim(),
                vision: formState.vision?.trim() || null,
                mission: formState.mission?.trim() || null,
                description: formState.description?.trim() || null,
                company_culture: formState.company_culture?.trim() || null,
                values: Object.fromEntries(
                    Object.entries(values).filter(
                        ([key, value]) => typeof key === 'string' && typeof value === 'string'
                    )
                ),
                industry: formState.industry?.trim() || null,
                founded: formState.founded?.trim() || null,
                num_employees: Number.isInteger(formState.num_employees)
                    ? formState.num_employees
                    : null,
                headquarters: formState.headquarters?.trim() || null,
                website: ensureURLScheme(formState.website?.trim()),
                email: formState.email?.trim() || null,
                phone: formState.phone?.trim() || null,
                other_addresses: otherAddresses.filter((address) => address?.trim() !== ''),
                social_media: Object.fromEntries(
                    Object.entries(socialMedias)
                        .filter(
                            ([platform, url]) => typeof platform === 'string' && url.trim() !== ''
                        )
                        .map(([platform, url]) => [platform, ensureURLScheme(url)])
                ),
                products: products
                    .filter(
                        (product) =>
                            product.product.name?.trim() && product.product.description?.trim()
                    )
                    .map((product) => product.product),
                services: services
                    .filter(
                        (service) =>
                            service.service.name?.trim() && service.service.description?.trim()
                    )
                    .map((service) => service.service),
            };

            console.log(payload);

            let newErrors = inputValidation();

            if (Object.keys(newErrors).length > 0) {
                setErrors(newErrors);
                setSnackbarMessage('Fill out required fields.');
                setSnackbarOpen(true);
                return;
            } else {
                setErrors({});
            }

            setIsLoading(true);

            try {
                await onCreateCompany(payload);
            } catch (error) {
                console.error('Error creating company:', error);
                setSnackbarMessage('There was an error creating the company');
                setSnackbarOpen(true);
            } finally {
                setIsLoading(false); // Stop loading
            }

        } else {
            setIsLoading(true);

            const productPromise = processAdditionsAndRemovals(
                initialProducts,
                products,
                'product',
                existingCompany.pk,
                ADD_PRODUCT_ENDPOINT,
                REMOVE_PRODUCT_ENDPOINT,
                setProducts
            );

            const servicePromise = processAdditionsAndRemovals(
                initialServices,
                services,
                'service',
                existingCompany.pk,
                ADD_SERVICE_ENDPOINT,
                REMOVE_SERVICE_ENDPOINT,
                setServices
            );

            // Call updateCompany and get the promise
            const updateCompanyPromise = updateCompany(
                existingCompany.pk,
                formState,
                values,
                socialMedias,
                UPDATE_COMPANY_ENDPOINT,
                API_TOKEN
            );

            Promise.all([productPromise, servicePromise, updateCompanyPromise])
                .then(([productResults, serviceResults, updatedCompany]) => {
                    setIsLoading(false);
                    onSaveChanges(updatedCompany);
                })
                .catch((error) => {
                    console.error('Error saving changes:', error);
                    setIsLoading(false);
                });

        }
    };

    const inputValidation = () => {
        let newErrors = {};
        requiredFields.forEach((field) => {
            if (!formState[field] || formState[field].trim() === '') {
                newErrors[field] = 'This field is required.';
            }
        });
        return newErrors;
    }

    const updateCompany = async (
        companyPk,
        editableCompany,
        values,
        socialMedias,
        UPDATE_COMPANY_ENDPOINT,
        API_TOKEN
    ) => {
        const token = getCookie('token'); // Retrieve the token for API authentication

        // Create the payload for the company update
        const companyUpdatePayload = {
            pk: companyPk,
            name: editableCompany.name || null,
            vision: editableCompany.vision || null,
            mission: editableCompany.mission || null,
            description: editableCompany.description || null,
            company_culture: editableCompany.company_culture || null,
            values:
                values.length > 0
                    ? values.reduce((acc, [key, value]) => ({ ...acc, [key]: value }), {})
                    : null,
            industry: editableCompany.industry || null,
            founded: editableCompany.founded || null,
            num_employees: parseInt(editableCompany.num_employees) || null,
            headquarters: editableCompany.headquarters || null,
            website: editableCompany.website || null,
            email: editableCompany.email || null,
            phone: editableCompany.phone || null,
            address: editableCompany.address || null,
            social_media:
                Object.keys(socialMedias).length > 0 ? socialMedias : null,
        };

        console.log('Company update payload:', companyUpdatePayload);

        // Return the fetch promise
        try {
            const response = await fetch(UPDATE_COMPANY_ENDPOINT, {
                method: 'POST', // Adjust method if needed
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    'api-key': API_TOKEN,
                },
                body: JSON.stringify(companyUpdatePayload),
            });
            const result_1 = await response.json();
            console.log('Company updated:', result_1);
            return result_1.company;
        } catch (error) {
            console.error('Error updating company:', error);
            throw error; // Re-throw to catch in saveChanges
        }
    };

    const processAdditionsAndRemovals = (
        initialItems,
        currentItems,
        itemKey,
        companyPk,
        addEndpoint,
        removeEndpoint,
        setItems
    ) => {
        const token = getCookie('token');

        const currentItemPks = currentItems
            .filter((item) => item[itemKey].pk)
            .map((item) => item[itemKey].pk);

        const itemsToRemove = initialItems.filter(
            (item) => !currentItemPks.includes(item[itemKey].pk)
        );

        const itemsToAdd = currentItems.filter((item) => !item[itemKey].pk);

        const fetchPromises = []; // **Collect fetch promises**

        // Remove items
        itemsToRemove.forEach((item) => {
            const data = {
                company_pk: companyPk,
                [`${itemKey}_pk`]: item[itemKey].pk,
            };

            console.log('payload', data);
            const fetchPromise = fetch(removeEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    'api-key': API_TOKEN,
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((result) => {
                    console.log(`${itemKey} removed:`, result);
                })
                .catch((error) => {
                    console.error(`Error removing ${itemKey}:`, error);
                });

            fetchPromises.push(fetchPromise);
        });

        // Add new items
        itemsToAdd.forEach((item) => {
            const data = {
                pk: companyPk,
                [itemKey]: item[itemKey],
            };
            const fetchPromise = fetch(addEndpoint, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    Authorization: `Bearer ${token}`,
                    'api-key': API_TOKEN,
                },
                body: JSON.stringify(data),
            })
                .then((response) => response.json())
                .then((result) => {
                    setItems((prevItems) =>
                        prevItems.map((i) => (i === item ? { [itemKey]: result[itemKey] } : i))
                    );
                    console.log(`${itemKey} added:`, result);
                })
                .catch((error) => {
                    console.error(`Error adding ${itemKey}:`, error);
                });

            fetchPromises.push(fetchPromise);
        });

        // **Return a Promise that resolves when all fetches are complete**
        return Promise.all(fetchPromises);
    };


    return (
        <div className="new-company-page" ref={containerRef}>
            <CustomSnackbar
                open={snackbarOpen}
                onClose={() => setSnackbarOpen(false)}
                message={snackbarMessage}
                backgroundColor="#f8d7da"
                textColor="#721c24"
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                autoHideDuration={3000}
            />
            <>
                {step === 1 && <CompanyOverview
                    formState={formState}
                    errors={errors}
                    handleChange={handleChange}
                    handleNextStep={handleNextStep}
                    handlePreviousStep={handlePreviousStep}
                    mode={mode}
                />}
                {step === 2 && <ValuesCulture
                    formState={formState}
                    errors={errors}
                    handleChange={handleChange}
                    handleNextStep={handleNextStep}
                    handlePreviousStep={handlePreviousStep}
                    handleValueChange={handleValueChange}
                    values={values}
                    newValue={newValue}
                    addValue={addValue}
                    removeValue={removeValue}
                />}
                {step === 3 && <BusinessPortfolio
                    formState={formState}
                    errors={errors}
                    handleChange={handleChange}
                    handleNextStep={handleNextStep}
                    handlePreviousStep={handlePreviousStep}
                    productDetails={productDetails}
                    serviceDetails={serviceDetails}
                    handleProductChange={handleProductChange}
                    handleServiceChange={handleServiceChange}
                    addProduct={addProduct}
                    removeProduct={removeProduct}
                    addService={addService}
                    removeService={removeService}
                    products={products}
                    services={services}
                />}
                {step === 4 && <Contact
                    formState={formState}
                    errors={errors}
                    handleChange={handleChange}
                    handleNextStep={handleNextStep}
                    handlePreviousStep={handlePreviousStep}
                    newAddress={newAddress}
                    addAddress={addAddress}
                    setNewAddress={setNewAddress}
                    otherAddresses={otherAddresses}
                    removeAddress={removeAddress}
                    newSocialMedia={newSocialMedia}
                    handleSocialMediaChange={handleSocialMediaChange}
                    addSocialMedia={addSocialMedia}
                    removeSocialMedia={removeSocialMedia}
                    socialMedias={socialMedias}
                    handleSubmit={handleSubmit}
                />}
            </>
            {isLoading && (
                <div className="loading-overlay">
                    <CircularProgress />
                </div>
            )}
        </div>
    );
};

export default NewCompany;
