import apiConfig from '../../../config/apiConfig';
import { getCookie } from '../../../components/cookieHelpers';

const API_GET_PRODUCT_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getProduct}`;
const API_GET_SERVICE_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getService}`;
const API_CREATE_COMPANY_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.createCompany}`;
const API_UPDATE_COMPANY_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.updateCompany}`;
const API_GET_COMPANIES_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getCompanies}`;
const API_TOKEN = apiConfig.token;

/**
 * Fetches the list of companies.
 * @returns {Promise<Array>} List of companies.
 */
export const fetchCompanies = async () => {
    const token = getCookie('token');
    try {
        const response = await fetch(API_GET_COMPANIES_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'api-key': API_TOKEN,
            },
            body: JSON.stringify({
                company: '',
                pk: '*',
            }),
        });

        if (!response.ok) {
            throw new Error('Failed to fetch companies');
        }

        const data = await response.json();
        return data.companies;
    } catch (error) {
        console.error('Error fetching companies:', error);
        throw error;
    }
};

export const fetchProducts = async (selectedCompany) => {
    const token = getCookie('token');
    try {
        const productPromises = (selectedCompany?.products || []).map(pk =>
            fetch(API_GET_PRODUCT_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'api-key': API_TOKEN,
                },
                body: JSON.stringify({ company_pk: selectedCompany.pk, product_pk: pk }),
            }).then(res => res.json())
        );
        const productData = await Promise.all(productPromises);
        return productData;
    } catch (error) {
        console.error('Error fetching products:', error);
    }
};

export const fetchServices = async (selectedCompany) => {
    const token = getCookie('token');
    try {
        const servicePromises = (selectedCompany?.services || []).map(pk =>
            fetch(API_GET_SERVICE_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'api-key': API_TOKEN,
                },
                body: JSON.stringify({ company_pk: selectedCompany.pk, service_pk: pk }),
            }).then(res => res.json())
        );
        const serviceData = await Promise.all(servicePromises);
        return serviceData;
    } catch (error) {
        console.error('Error fetching services:', error);
    }
};

export const createCompany = async (newCompany) => {
    try {
        const token = getCookie('token');
        const response = await fetch(API_CREATE_COMPANY_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'api-key': API_TOKEN,
            },
            body: JSON.stringify(newCompany),
        });
        const data = await response.json();

        if (!response.ok) {
            throw new Error('Failed to create company');
        }
        return data;
    } catch (error) {
        alert('Failed to create company. Please try again.');
    }
};

/**
 * Updates an existing company.
 * @param {Object} updatedCompanyData - Updated company data.
 * @returns {Promise<Object>} Updated company data.
 */
export const updateCompany = async (updatedCompanyData) => {
    const token = getCookie('token');
    try {
        const response = await fetch(API_UPDATE_COMPANY_ENDPOINT, {
            method: 'POST', 
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'api-key': API_TOKEN,
            },
            body: JSON.stringify(updatedCompanyData),
        });

        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage = errorData?.message || 'Failed to update company';
            throw new Error(errorMessage);
        }

        const data = await response.json();
        return data;
    } catch (error) {
        console.error('Error updating company:', error);
        throw error;
    }
};