import { React, useState, useRef, useLayoutEffect } from 'react';
import './CompanyList.css';
import './newCompanyFlow/NewCompany.css';
import KodelabFilledPillButton from '../../../components/KodelabFilledPillButton';
import { ReactComponent as SearchIcon } from '../../../assets/SearchIcon.svg';
import back_arrow from '../../../assets/BsChevronLeft.png';

const CompanyList = ({
  companies,
  isLoadingCompanies,
  fetchError,
  selectedCompany,
  onCompanyClick,
  setIsCreating,
  setIsEditing,
  mode = 'none',
  step,
  setStep,
}) => {
  const [searchQuery, setSearchQuery] = useState('');
  const companyRefs = useRef({}); // Ref object to hold company item refs

  // Effect to scroll to the selected company
  useLayoutEffect(() => {
    if (selectedCompany && companyRefs.current[selectedCompany.pk]) {
      const element = companyRefs.current[selectedCompany.pk];
      const container = document.querySelector('.company-list'); // Adjust selector if necessary

      if (container && element) {
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        if (elementRect.top < containerRect.top) {
          // Element is above the visible area
          container.scrollBy({
            top: elementRect.top - containerRect.top,
            behavior: 'smooth',
          });
        } else if (elementRect.bottom > containerRect.bottom) {
          // Element is below the visible area
          container.scrollBy({
            top: elementRect.bottom - containerRect.bottom,
            behavior: 'smooth',
          });
        }
        // Else, element is in view, do nothing
      }
    }
  }, [selectedCompany]);

  return (
    <aside className="companies-sidebar">
      {mode === 'create' || mode === 'edit' ? (
        <div className="company-sidebar-new-header">
          <div className="companies-sidebar-title">
            <div className="company-eval-back-btn">
              <img src={back_arrow} className="back-arrow-icon" alt="Back" />
              <p
                onClick={() => {
                  setIsCreating(false);
                  setIsEditing(false);
                  setStep(1);
                }}
              >
                Go back
              </p>
            </div>
          </div>
          <h2>
            {mode === 'create' ? 'New Company' : mode === 'edit' ? 'Edit Company' : ''}
          </h2>
          <ul>
            <li className={step === 1 ? 'active' : ''}>Company Overview</li>
            <li className={step === 2 ? 'active' : ''}>Values & Culture</li>
            <li className={step === 3 ? 'active' : ''}>Business Portfolio</li>
            <li className={step === 4 ? 'active' : ''}>Contact</li>
          </ul>
        </div>
      ) : (
        <>
          <div className="companies-header">
            <div className="companies-header-title">
              <h3>Companies</h3>
              <KodelabFilledPillButton
                outlined={true}
                style={{ padding: '8px 16px', color: '#202020', fontSize: '16px' }}
                onClick={() => setIsCreating(true)}
              >
                + Create new
              </KodelabFilledPillButton>
            </div>
            <div className="search-bar-container">
              <SearchIcon className="search-icon" />
              <input
                type="text"
                className="search-bar"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
          </div>
          <ul className="company-list">
            {isLoadingCompanies ? (
              <li className="no-companies-message">Loading companies...</li>
            ) : fetchError ? (
              <li className="no-companies-message">{fetchError}</li>
            ) : companies.length === 0 ? (
              <li className="no-companies-message">No companies found</li>
            ) : (
              companies
                .filter((company) =>
                  company.company.name.toLowerCase().includes(searchQuery.toLowerCase())
                )
                .map((company) => (
                  <li
                    key={company.company.pk}
                    ref={(el) => (companyRefs.current[company.company.pk] = el)} // Assign ref
                    className={`company-item ${selectedCompany && selectedCompany.pk === company.company.pk ? 'selected' : ''
                      }`}
                    onClick={() => onCompanyClick(company.company)}
                  >
                    <div className="company-content">
                      <h3 className="company-name">{company.company.name}</h3>
                      <p className="company-industry">{company.company.industry}</p>
                    </div>
                  </li>
                ))
            )}
          </ul>
        </>
      )}
    </aside>
  );
};

export default CompanyList;