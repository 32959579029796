import React, { useState, useLayoutEffect, useRef, useEffect } from 'react';
import { Menu, MenuItem } from '@mui/material';
import back_arrow from '../../../assets/BsChevronLeft.png';
import { ReactComponent as SearchIcon } from '../../../assets/SearchIcon.svg';
import KodelabFilledPillButton from '../../../components/KodelabFilledPillButton';
import KodelabSelect from '../../../components/KodelabSelect';
import './VacancyList.css';

const VacancyList = ({
  companies,
  vacancies,
  isLoadingVacancies,
  fetchError,
  selectedVacancy,
  handleCreateNew,
  handleVacancyClick,
  handleDeleteVacancy,
  startEditingVacancy,
  copyLinkToClipboard,
  filter,
  searchQuery,
  setFilter,
  setSearchQuery,
  isNewVacancyShown,
  newVacancyStep,
  setNewVacancyStep,
  goBack,
  selectedCompany,
  setSelectedCompany
}) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentVacancy, setCurrentVacancy] = useState(null);
  const vacancyRefs = useRef({});

  // Effect to set the default selected company when companies data is available
  useEffect(() => {
    if (companies && companies.length > 0) {
      setSelectedCompany(companies[0]);
    } else {
      setSelectedCompany('');
    }
  }, [companies]);

  // Effect to scroll to the selected vacancy
  useLayoutEffect(() => {
    if (selectedVacancy && vacancyRefs.current[selectedVacancy.pk]) {
      const element = vacancyRefs.current[selectedVacancy.pk];
      const container = document.querySelector('.vacancy-list');

      if (container && element) {
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        if (elementRect.top < containerRect.top) {
          container.scrollBy({
            top: elementRect.top - containerRect.top,
            behavior: 'smooth',
          });
        } else if (elementRect.bottom > containerRect.bottom) {
          container.scrollBy({
            top: elementRect.bottom - containerRect.bottom,
            behavior: 'smooth',
          });
        }
      }
    }
  }, [selectedVacancy]);

  const handleMenuOpen = (event, vacancy) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentVacancy(vacancy);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentVacancy(null);
  };

  return (
    <aside className="vacancies-sidebar">
      {isNewVacancyShown ? (
        <div className="vacancy-sidebar-new-header">
          <div className="vacancies-sidebar-title">
            <div className="vacancy-eval-back-btn">
              <img src={back_arrow} className="back-arrow-icon" alt="Back" />
              <p onClick={goBack}>Go back</p>
            </div>
          </div>
          <h2>New vacancy</h2>
          <ul>
            <li className={newVacancyStep === 1 ? 'active' : ''}>Job description</li>
            <li className={newVacancyStep === 2 ? 'active' : ''}>Interview questions</li>
            <li className={newVacancyStep === 3 ? 'active' : ''}>Review</li>
            <li
              className={`vacancy-sidebar-new-header li ${newVacancyStep === 4 ? 'active' : ''
                } success`}
            >
              Success
            </li>
          </ul>
        </div>
      ) : (
        <div className="vacancies-sidebar-header">
          <div className="vacancies-sidebar-title">
            <h3>Vacancies</h3>
            <KodelabFilledPillButton
              outlined={true}
              style={{ padding: '8px 16px', color: '#202020', fontSize: '16px' }}
              onClick={handleCreateNew}
            >
              + Create new
            </KodelabFilledPillButton>
          </div>
          <div className="search-and-filter">
            <div className="search-bar-container">
              <SearchIcon className="search-icon" />
              <input
                type="text"
                className="search-bar"
                placeholder="Search..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
              />
            </div>
            <div className="bottom-section">
              <KodelabSelect
                placeholder="Filter"
                options={[
                  { value: 'all', label: 'All' },
                  { value: 'active', label: 'Active' },
                  { value: 'inactive', label: 'Inactive' },
                ]}
                value={filter}
                onChange={(e) => setFilter(e.target.value)}
                style={{ width: '36%', marginRight: '4%' }}
                inputStyle={{ height: '39px', padding: '0 0 0 12px', color: '#858585' }}
              />
              <KodelabSelect
                placeholder={companies && companies.length > 0 ? 'Company' : 'Loading...'}
                options={
                  companies && companies.length > 0
                    ? companies.map((company) => ({
                      value: company.company.name,
                      label: company.company.name,
                    }))
                    : []
                }
                value={selectedCompany ? selectedCompany.company.name : ''}
                onChange={(e) => {
                  const selectedCompanyName = e.target.value;
                  const company = companies.find(c => c.company.name === selectedCompanyName);
                  setSelectedCompany(company);
                }}
                style={{ width: '60%' }}
                inputStyle={{ height: '39px', padding: '0 0 0 12px', color: '#858585' }}
                disabled={!companies || companies.length === 0}
              />
            </div>
          </div>
        </div>
      )}
      {!isNewVacancyShown && (
        <ul className="vacancy-list">
          {isLoadingVacancies ? (
            <li className="no-vacancies-message">Loading vacancies...</li>
          ) : fetchError ? (
            <li className="no-vacancies-message">{fetchError}</li>
          ) : vacancies.length === 0 ? (
            <li className="no-vacancies-message">No vacancies found</li>
          ) : (
            vacancies
              .filter((vacancy) => {
                const matchesFilter =
                  filter === 'all' ||
                  (filter === 'active' && vacancy.active) ||
                  (filter === 'inactive' && !vacancy.active);

                const matchesSearchQuery = vacancy.job_title
                  .toLowerCase()
                  .includes(searchQuery.toLowerCase());

                return matchesFilter && matchesSearchQuery;
              })
              .map((vacancy) => (
                <li
                  key={vacancy.pk}
                  ref={(el) => (vacancyRefs.current[vacancy.pk] = el)}
                  className={`vacancy-item ${selectedVacancy && selectedVacancy.pk === vacancy.pk ? 'selected' : ''
                    }`}
                  onClick={() => handleVacancyClick(vacancy)}
                >
                  <div className="vacancy-content">
                    <h3 className="vacancy-title">{vacancy.job_title}</h3>
                    <p className="vacancy-active">{vacancy.active ? 'Active' : 'Inactive'}</p>
                  </div>
                  <div className="dropdown-container">
                    <button
                      className="dropdown-btn"
                      onClick={(e) => handleMenuOpen(e, vacancy)}
                    >
                      ⋮
                    </button>
                  </div>
                </li>
              ))
          )}
        </ul>
      )}

      <Menu anchorEl={anchorEl} open={Boolean(anchorEl)} onClose={handleMenuClose}>
        <MenuItem
          onClick={() => {
            startEditingVacancy(currentVacancy);
            handleMenuClose();
          }}
        >
          Edit details
        </MenuItem>
        <MenuItem
          onClick={() => {
            copyLinkToClipboard(currentVacancy.pk);
            handleMenuClose();
          }}
        >
          Copy Link
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleDeleteVacancy(currentVacancy.pk);
            handleMenuClose();
          }}
          style={{ color: 'red' }}
        >
          Close Vacancy
        </MenuItem>
      </Menu>
    </aside>
  );
};

export default VacancyList;