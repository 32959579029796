// CustomInput.js
import React from 'react';

const KodelabInput = ({
    label,
    placeholder,
    style = {},
    inputStyle = {},
    labelStyle = {},
    type = 'text',
    required = false,
    ...props
}) => {
    const defaultInputStyle = {
        boxSizing: 'border-box',
        width: '100%',
        height: '54px',
        padding: '15px',
        margin: '1px',
        marginBottom: '10px',
        border: '1px solid #EBEBEB',
        borderRadius: '8px',
        color: "#202020",
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22px',
        ...inputStyle,
    };

    const defaultLabelStyle = {
        display: 'block',
        marginBottom: '2px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22px',
        ...labelStyle,
    };

    return (
        <div style={style}>
            {label && (
                <label style={defaultLabelStyle}>
                    {label}
                    {required && <span> *</span>}
                </label>
            )}
            <input
                type={type}
                placeholder={placeholder}
                style={defaultInputStyle}
                {...props}
            />
        </div>
    );
};

export default KodelabInput;