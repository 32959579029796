import React, { useState, useLayoutEffect, useRef } from 'react';
import { Menu, MenuItem } from '@mui/material';
import './CandidateList.css';
import { ReactComponent as SearchIcon } from '../../../assets/SearchIcon.svg';
import KodelabSelect from '../../../components/KodelabSelect';
const CandidateList = ({ candidates, isLoadingCandidates, fetchError, selectedCandidate, onCandidateClick, onEditCandidate, onDeleteCandidate, companies, selectedCompany, setSelectedCompany }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentCandidate, setCurrentCandidate] = useState(null);
  const [searchQuery, setSearchQuery] = useState('');
  const candidateRefs = useRef({});

  // Effect to scroll to the selected company
  useLayoutEffect(() => {
    if (currentCandidate && candidateRefs.current[currentCandidate.pk]) {
      const element = candidateRefs.current[currentCandidate.pk];
      const container = document.querySelector('.candidate-list'); // Adjust selector if necessary

      if (container && element) {
        const elementRect = element.getBoundingClientRect();
        const containerRect = container.getBoundingClientRect();

        if (elementRect.top < containerRect.top) {
          // Element is above the visible area
          container.scrollBy({
            top: elementRect.top - containerRect.top,
            behavior: 'smooth',
          });
        } else if (elementRect.bottom > containerRect.bottom) {
          // Element is below the visible area
          container.scrollBy({
            top: elementRect.bottom - containerRect.bottom,
            behavior: 'smooth',
          });
        }
        // Else, element is in view, do nothing
      }
    }
  }, [currentCandidate]);

  const handleMenuOpen = (event, candidate) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
    setCurrentCandidate(candidate);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    setCurrentCandidate(null);
  };

  return (
    <aside className="candidates-sidebar">
      <div className="candidates-sidebar-header">
        <h3>Candidates</h3>
        <div className="sidebar-interactives">
          <div className="search-bar-container">
            <SearchIcon className="search-icon" />
            <input
              type="text"
              className="search-bar"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
            />
          </div>
          <KodelabSelect
            placeholder={companies && companies.length > 0 ? 'Company' : 'Loading...'}
            options={
              companies && companies.length > 0
                ? companies.map((company) => ({
                  value: company.company.name,
                  label: company.company.name,
                }))
                : []
            }

            value={selectedCompany ? selectedCompany.company.name : ''}
            onChange={(e) => {
              const selectedCompanyName = e.target.value;
              const company = companies.find(c => c.company.name === selectedCompanyName);
              setSelectedCompany(company);
            }}
            style={{ width: '60%' }}
            inputStyle={{ height: '40px', padding: '0 0 0 12px', color: '#858585', marginBottom: '0px', marginTop: '0px', marginLeft: '0px', marginRight: '0px' }}
            disabled={!companies || companies.length === 0}
          />
        </div>
      </div>
      <ul className="candidate-list">
        {isLoadingCandidates ? (
          <li className="no-candidates-message">Loading candidates...</li>
        ) : fetchError ? (
          <li className="no-candidates-message">{fetchError}</li>
        ) : candidates?.length === 0 ? (
          <li className="no-candidates-message">No candidates found</li>
        ) : (
          candidates?.filter((candidate) =>
            candidate.name.toLowerCase().includes(searchQuery.toLowerCase()))
            .map((candidate) => (
              <li
                key={candidate.pk}
                ref={(el) => (candidateRefs.current[candidate.pk] = el)} // Assign ref
                className={`candidate-item ${selectedCandidate && selectedCandidate.pk === candidate.pk ? 'selected' : ''}`}
                onClick={() => onCandidateClick(candidate)}
              >
                <div className="candidate-content">
                  <h3 className="candidate-name">{candidate.name}</h3>
                </div>
                <div className="dropdown-container">
                  <button
                    className="dropdown-btn"
                    onClick={(e) => handleMenuOpen(e, candidate)}
                  >
                    ⋮
                  </button>
                </div>
              </li>
            ))
        )}
      </ul>

      <Menu
        anchorEl={anchorEl}
        open={Boolean(anchorEl)}
        onClose={handleMenuClose}
      >
        <MenuItem onClick={() => {
          onEditCandidate(currentCandidate);
          handleMenuClose();
        }}>
          Edit details
        </MenuItem>
        <MenuItem
          onClick={() => {
            onDeleteCandidate(currentCandidate.pk);
            handleMenuClose();
          }}
          style={{ color: 'red' }}
        >
          Delete
        </MenuItem>
      </Menu>
    </aside>
  );
};

export default CandidateList;
