import React from 'react';
import pickey from '../../assets/pickey.png'; // Update this path accordingly

const BlueCircleIcon = () => (
  <svg
    width="16"
    height="16"
    style={{ marginRight: '10px', verticalAlign: 'middle' }}
    aria-hidden="true"
  >
    <circle cx="8" cy="8" r="8" fill="#0097F5" />
  </svg>
);

function Slide3() {
  const styles = {
    slide1Content: {
      textAlign: 'center',
      height: '95%',
      backgroundColor: '#007BFF1A',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '20px',
      padding: '20px',
    },
    imageContainer: {
      position: 'relative',
      display: 'inline-block',
    },
    slide1TitleContainer: {
      position: 'absolute',
      top: '22px',
      left: '-20px',
      textAlign: 'left',
      zIndex: 1,
    },
    slide1TitleKiwi: {
      fontSize: '24px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      margin: 0,
      marginBottom: '20px',
      display: 'flex',
      alignItems: 'center',
    },
    slide1Subtitle: {
      color: '#3D3D3D',
      fontFamily: 'Lato, sans-serif', // Ensure 'Lato' font is loaded in your project
      fontSize: '36px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: 'normal',
      margin: 0,
    },
    kiwiImage: {
      maxWidth: '600px',
      height: 'auto',
      marginTop: '100px',
    },
  };

  return (
    <div style={styles.slide1Content} className="mob-contain-slide3">
      <div style={styles.imageContainer}>
        <img src={pickey} alt="Pickey" style={styles.kiwiImage} className="mob-img-slide3" />
        <div style={styles.slide1TitleContainer} className="mob-title-container">
          <h3 style={styles.slide1TitleKiwi} className="mob-title-slide3">
            <BlueCircleIcon /> Pickey
          </h3>
          <h2 style={styles.slide1Subtitle} className="mob-subtitle-slide3">
            Simplify Hiring with Pickey — Discover the Right Candidate with Precision
          </h2>
        </div>
      </div>
    </div>
  );
}

export default Slide3;