import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, NavLink, useLocation, useNavigate } from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { Menu, MenuItem } from '@mui/material';

import Vacancies from './features/pickey/vacancies/Vacancies';
import Candidates from './features/pickey/candidates/Candidates';
import Companies from './features/pickey/companies/Companies';

import Profile from './features/kopi/profile/Profile';
import Evaluation from './features/kopi/evaluation/Evaluation';
import PerformanceReview from './features/kopi/performance_review/Performance';

import Login from './features/landing/Login2';
import Interview from './features/pickey/interview/Interview';
import ProtectedRoute from './ProtectedRoute';
import './App.css';
import { ReactComponent as KodelabLogo } from './assets/kodelab-full-logo.svg';
import { ReactComponent as KodeAILogo } from './assets/KodeAILogo.svg';
import { ReactComponent as Default_PFP } from './assets/Default_Profile.svg';
import Default_PFP_PNG from './assets/Default_Profile_NOSCALE.png'
import KodelabMenu from './components/KodelabMenu';
import userIcon from './assets/user-blank.png';
import apiConfig from './config/apiConfig';

const API_GET_AUTH_TOKEN = `${apiConfig.baseURL}${apiConfig.endpoints.getAuthToken}`;
const API_TOKEN = apiConfig.token;

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const [currentApp, setCurrentApp] = useState('Pickey');
  const [isLoading, setIsLoading] = useState(true);
  const [accountName, setAccountName] = useState('Pickey');
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      setIsAuthenticated(true);
    } else {
      setIsAuthenticated(false);
    }
    setIsLoading(false);
  }, []);

  const handleLogin = async (username, password) => {
    try {
      const formData = new FormData();
      formData.append('username', username);
      formData.append('password', password);

      const response = await fetch(API_GET_AUTH_TOKEN, {
        method: 'POST',
        headers: {
          'api-key': API_TOKEN
        },
        body: formData
      });

      if (response.ok) {
        const data = await response.json();
        console.log('logindata', data);
        const token = data.access_token;

        if (data.account !== null && data.account !== undefined) {
          const accountName = data.account;
          localStorage.setItem('account_name', accountName);
          setAccountName(accountName);
        }

        document.cookie = `token=${token}; path=/; secure; SameSite=Strict; max-age=3600`; //Redundant now... but leaving incase it breaks something
        localStorage.setItem('token', token);
        setIsAuthenticated(true);

        localStorage.setItem('isAuthenticated', 'true');
        if (currentApp == 'Pickey') {
          navigate('/vacancies');
        } else {
          navigate('/profile')
        }
        return true;
      } else if (response.status === 401 || response.status === 401) {
        console.error('Unauthorized: Invalid username or password');
      }
      return false;
    } catch (error) {
      console.error('Login failed', error);
      return false;
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('token');
    localStorage.removeItem('account_name');
    setAccountName('Pickey');
    navigate('/');
  };

  const handleMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
  };

  const handleLogoClick = () => {
    setCurrentApp(currentApp === 'Pickey' ? 'Kopi' : 'Pickey');
    console.log(currentApp);
  };

  const isLoginPage = location.pathname === '/';
  const isInterviewPage = location.pathname.startsWith('/interview');

  return (
    <div className="app">
      {(
        <nav className="navbar">
          <div className="navbar-left">
            <KodelabLogo
              className="logo"
            />
            {isLoginPage && (
              <KodeAILogo />
            )}
          </div>
          {['Pickey', 'Kopi'].includes(currentApp) && !isLoginPage && !isInterviewPage && (
            <>
              <div className="navbar-center">
                {currentApp === 'Pickey' ? (
                  <>
                    <NavLink to="/companies" className="nav-link">Companies</NavLink>
                    <NavLink to="/vacancies" className="nav-link">Vacancies</NavLink>
                    <NavLink to="/candidates" className="nav-link">Candidates</NavLink>
                  </>
                ) : (
                  <>
                    <NavLink to="/profile" className="nav-link">Profile</NavLink>
                    <NavLink to="/evaluation" className="nav-link">Evaluation</NavLink>
                    <NavLink to="/performance" className="nav-link">Performance Review</NavLink>
                  </>
                )}
              </div>
              <div className="navbar-right">

                <Default_PFP onClick={handleMenuOpen} />

                <KodelabMenu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleMenuClose}
                  imageComponent={<Default_PFP />}
                  title={accountName}
                  titleStyle={{
                    fontFamily: 'Lato',
                    fontSize: '18px',
                    fontStyle: 'normal',
                    fontWeight: 500,
                    lineHeight: 'normal',
                  }}
                  menuItems={[
                    {
                      label: 'Settings',
                      onClick: () => {
                        // Handle settings click
                      },
                    },
                    {
                      label: 'Logout',
                      onClick: handleLogout,
                      style: { color: 'red' },
                    },
                  ]}
                />
              </div>
            </>
          )}
        </nav>

      )}
      <div className="content">
        <Routes location={location}>
          <Route path="/" element={<Login handleLogin={handleLogin} handleLogoClick={handleLogoClick} currentApp={currentApp} />} />
          <Route path="/vacancies" element={<ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading}><Vacancies /></ProtectedRoute>} />
          <Route path="/candidates" element={<ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading}><Candidates /></ProtectedRoute>} />
          <Route path="/companies" element={<ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading}><Companies /></ProtectedRoute>} />
          <Route path="/profile" element={<ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading}><Profile /></ProtectedRoute>} />
          <Route path="/evaluation" element={<ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading}><Evaluation /></ProtectedRoute>} />
          <Route path="/performance" element={<ProtectedRoute isAuthenticated={isAuthenticated} isLoading={isLoading}><PerformanceReview /></ProtectedRoute>} />
          <Route path="/interview" element={<Interview />} />
        </Routes>
      </div>
    </div>
  );
}

export default App;
