// KodelabPasswordInput.js
import React, { useState } from 'react';
import { ReactComponent as EyeIcon } from '../assets/BsEye.svg';
import { ReactComponent as EyeSlashIcon } from '../assets/BsEyeSlash.svg';

const KodelabPasswordInput = ({
    label,
    placeholder,
    style = {},
    inputStyle = {},
    labelStyle = {},
    ...props
}) => {
    const [showPassword, setShowPassword] = useState(false);

    const togglePasswordVisibility = () => {
        setShowPassword((prevState) => !prevState);
    };

    const defaultInputStyle = {
        boxSizing: 'border-box',
        width: '100%',
        height: '54px',
        padding: '15px',
        margin: '1px',
        marginBottom: '10px',
        border: '1px solid #EBEBEB',
        borderRadius: '8px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22px',
        ...inputStyle,
    };

    const defaultLabelStyle = {
        display: 'block',
        marginBottom: '2px',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: '400',
        lineHeight: '22px',
        ...labelStyle,
    };

    const containerStyle = {
        position: 'relative',
        width: '100%',
    };

    const buttonStyle = {
        position: 'absolute',
        top: '50%',
        right: '15px', 
        transform: 'translateY(-50%)',
        background: 'none',
        border: 'none',
        padding: '0',
        margin: '0',
        paddingBottom: '5px',
        cursor: 'pointer',
    };

    const iconStyle = {
        width: '20px',
        height: '20px',
    };

    return (
        <div style={style}>
            {label && <label style={defaultLabelStyle}>{label}</label>}
            <div style={containerStyle}>
                <input
                    type={showPassword ? 'text' : 'password'}
                    placeholder={placeholder}
                    style={defaultInputStyle}
                    {...props}
                />
                <button
                    type="button"
                    onClick={togglePasswordVisibility}
                    style={buttonStyle}
                    aria-label={showPassword ? 'Hide password' : 'Show password'}
                >
                    {showPassword ? (
                        <EyeSlashIcon style={iconStyle} />
                    ) : (
                        <EyeIcon style={iconStyle} />
                    )}
                </button>
            </div>
        </div>
    );
};

export default KodelabPasswordInput;