// hooks/useServices.js

import { useState, useEffect } from 'react';
import { fetchServices } from '../CompaniesService';

const useServices = (company) => {
  const [services, setServices] = useState([]);
  const [isLoadingServices, setIsLoadingServices] = useState(true);
  const [servicesError, setServicesError] = useState(null);

  useEffect(() => {
    if (!company) {
      setServices([]);
      setIsLoadingServices(false);
      setServicesError(null);
      return;
    }

    const loadServices = async () => {
      setIsLoadingServices(true);
      setServicesError(null);
      try {
        const data = await fetchServices(company);
        setServices(data);
      } catch (error) {
        setServicesError(error.message || 'Failed to fetch services');
      } finally {
        setIsLoadingServices(false);
      }
    };

    loadServices();
  }, [company]);

  return { services, isLoadingServices, servicesError };
};

export default useServices;