// hooks/useProducts.js

import { useState, useEffect } from 'react';
import { fetchProducts } from '../CompaniesService';

const useProducts = (company) => {
    const [products, setProducts] = useState([]);
    const [isLoadingProducts, setIsLoadingProducts] = useState(true);
    const [productsError, setProductsError] = useState(null);

    useEffect(() => {
        if (!company) {
            setProducts([]);
            setIsLoadingProducts(false);
            setProductsError(null);
            return;
        }

        const loadProducts = async () => {
            setIsLoadingProducts(true);
            setProductsError(null);
            try {
                const data = await fetchProducts(company);
                setProducts(data);
            } catch (error) {
                setProductsError(error.message || 'Failed to fetch products');
            } finally {
                setIsLoadingProducts(false);
            }
        };

        loadProducts();
    }, [company]);

    return { products, isLoadingProducts, productsError };
};

export default useProducts;