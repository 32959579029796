import React, { useState, useEffect } from 'react';
import * as pdfjsLib from 'pdfjs-dist/webpack';
import './JobDescriptionStep.css';
import apiConfig from '../../../../config/apiConfig';
import { getCookie } from '../../../../components/cookieHelpers';
import MenuItem from '@mui/material/MenuItem';
import TextField from '@mui/material/TextField';
import CustomTextArea from './CustomTextArea';

import { ReactComponent as GenerateIcon } from '../../../../assets/AiIcon.svg';
import { ReactComponent as UploadIcon } from '../../../../assets/PaperclipIcon.svg';

const API_GEN_JOB_DESCRIPTION = `${apiConfig.baseURL}${apiConfig.endpoints.generateJobDescription}`;
const API_GET_COMPANIES_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getCompanies}`;

const API_TOKEN = apiConfig.token;

const JobDescriptionStep = ({
  jobTitle, setJobTitle,
  salaryRange, setSalaryRange,
  experience, setExperience,
  location, setLocation,
  jobDescription, setJobDescription,
  showWarning, isGenerating,
  setIsGenerating, setShowWarning,
  handleNextStep,
  selectedCompanyPk, setSelectedCompanyPk
}) => {
  const [jobDescriptionError, setJobDescriptionError] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [locationOptions, setLocationOptions] = useState([]);
  const [jobType, setJobType] = useState('Full-time'); // State for job type
  const [workplaceType, setWorkplaceType] = useState('On-site'); // State for workplace type

  useEffect(() => {
    fetchCompanies();
  }, []);

  const fetchCompanies = async () => {
    const token = getCookie('token');
    try {
      const response = await fetch(API_GET_COMPANIES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify({ pk: '*' }),
      });

      const data = await response.json();
      setCompanies(data.companies); // Update state with fetched companies
    } catch (error) {
      console.warn(error);
    }
  };

  const handleFileUpload = async (event) => {
    const file = event.target.files[0];
    if (file && file.type === 'application/pdf') {
      const reader = new FileReader();
      reader.onload = async (e) => {
        const pdfData = new Uint8Array(e.target.result);
        const pdfDoc = await pdfjsLib.getDocument({ data: pdfData }).promise;
        const textContent = await extractTextFromPDF(pdfDoc);
        setJobDescription(textContent);
      };
      reader.readAsArrayBuffer(file);
    } else {
      alert('Please upload a PDF file.');
    }
  };

  const handleGenerateAI = async () => {
    setIsGenerating(true);

    const payload = {
      job_title: jobTitle,
      company: selectedCompanyPk,
      location: location,
      experience_level: experience,
      salary_range: salaryRange,
      job_type: jobType.toLowerCase(),
      workplace: workplaceType.toLowerCase(),
    };
    console.log(payload);
    try {
      const token = getCookie('token');
      const response = await fetch(API_GEN_JOB_DESCRIPTION, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN
        },
        body: JSON.stringify(payload)
      });
      const data = await response.json();
      const contentType = response.headers.get('content-type');
      if (!contentType || !contentType.includes('application/json')) {
        throw new Error('API did not return JSON');
      }

      const formattedText = formatJobDescription(data.response);
      setJobDescription(formattedText);

    } catch (error) {
      console.error('Error generating job description:', error);
      setJobDescription('Failed to generate job description. Please check the console for more details.');
    } finally {
      setIsGenerating(false);
    }
  };

  const extractTextFromPDF = async (pdfDoc) => {
    let textContent = '';
    for (let i = 0; i < pdfDoc.numPages; i++) {
      const page = await pdfDoc.getPage(i + 1);
      const text = await page.getTextContent();
      const pageText = text?.items?.map(item => item.str).join(' ');
      textContent += pageText + '\n';
    }
    return textContent;
  };

  const formatJobDescription = (response) => {
    let jsonResponse;
    if (typeof response === 'string') {
      jsonResponse = JSON.parse(response.replace(/```json\n|\n```/g, ''));
    } else {
      jsonResponse = response;
    }

    let formattedText = '';
    if (jsonResponse.skills) {
      formattedText += `Skills Required:\n${jsonResponse.skills.join(', ')}\n\n`;
    }
    if (jsonResponse.qualification) {
      formattedText += `Qualifications:\n${jsonResponse.qualification.join(', ')}\n\n`;
    }
    if (jsonResponse.experience) {
      formattedText += `Minimum Experience Required:\n${jsonResponse.experience.join(', ')}\n\n`;
    }
    if (jsonResponse.tasks) {
      formattedText += `Tasks Typically Required:\n${jsonResponse.tasks.join(', ')}\n\n`;
    }
    if (jsonResponse.certs) {
      formattedText += `Optional Certifications and Further Training:\n${jsonResponse.certs.join(', ')}\n\n`;
    }
    if (jsonResponse.soft_skills) {
      formattedText += `Soft Skills:\n${jsonResponse.soft_skills.join(', ')}\n`;
    }

    return formattedText;
  };

  const handleNext = () => {
    if (jobDescription.length >= 150) {
      handleNextStep();
    } else {
      setJobDescriptionError(true);
    }
  };

  const handleCompanyChange = (event) => {
    const selectedPk = event.target.value;
    setSelectedCompanyPk(selectedPk); // Set the selected company pk

    // Find selected company from companies list
    const selectedCompany = companies.find((companyData) => companyData.company.pk === selectedPk);

    if (selectedCompany) {
      // Set location options using headquarters and other addresses
      const { headquarters, other_addresses } = selectedCompany.company;
      const locations = [headquarters, ...(other_addresses || [])];
      setLocationOptions(locations);
      setLocation('');
    } else {
      setLocationOptions([]);
    }
  };

  // Function to check if the form is valid
  const isFormValid = () => {
    return (
      jobTitle &&
      salaryRange &&
      experience &&
      location &&
      jobDescription.length >= 150 &&
      selectedCompanyPk
    );
  };

  return (
    <form className="vacancy-form">
      <div className="form-jd-header">
        <h3>Who are you looking for?</h3>
        <p>Please share the job description or if you want you can upload a file with details</p>
      </div>
      <div className="form-grid">
        <TextField
          select
          label="Company"
          value={selectedCompanyPk}
          fullWidth
          required
          onChange={handleCompanyChange}
        >
          {companies?.map((companyData, index) => (
            <MenuItem key={index} value={companyData.company.pk}>
              {companyData.company.name}
            </MenuItem>
          ))}
        </TextField>
        <TextField
          label="Job position title"
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
          fullWidth
          disabled={isGenerating}
          required
          error={!jobTitle}
          helperText={!jobTitle ? 'Job title is required.' : ' '}
        />
        <TextField
          label="Minimum experience"
          value={experience}
          onChange={(e) => setExperience(e.target.value)}
          fullWidth
          disabled={isGenerating}
          required
          error={!experience}
          helperText={!experience ? 'Experience is required.' : ' '}
        />
        <TextField
          label="Salary range"
          value={salaryRange}
          onChange={(e) => setSalaryRange(e.target.value)}
          fullWidth
          disabled={isGenerating}
          required
          error={!salaryRange}
          helperText={!salaryRange ? 'Salary range is required.' : ' '}
        />
      </div>

      <div className="jd-form-row">
        <TextField
          select
          label="Job type"
          value={jobType}
          onChange={(e) => setJobType(e.target.value)}
          fullWidth
          disabled={isGenerating}
          required
        >
          <MenuItem value="Full-time">Full-time</MenuItem>
          <MenuItem value="Part-time">Part-time</MenuItem>
        </TextField>
        <TextField
          select
          label="Workplace type"
          value={workplaceType}
          onChange={(e) => setWorkplaceType(e.target.value)}
          fullWidth
          disabled={isGenerating}
          required
        >
          <MenuItem value="On-site">On-site</MenuItem>
          <MenuItem value="Remote">Remote</MenuItem>
        </TextField>
        <TextField
          select
          label="Location"
          value={location}
          onChange={(e) => setLocation(e.target.value)}
          fullWidth
          disabled={isGenerating || !selectedCompanyPk}
          required
          error={!location}
          helperText={!location ? 'Location is required.' : ' '}
        >
          {locationOptions?.map((loc, index) => (
            <MenuItem key={index} value={loc}>
              {loc}
            </MenuItem>
          ))}
        </TextField>
      </div>

      <CustomTextArea
        minRows={4}
        id="job-description"
        value={jobDescription}
        onChange={(e) => {
          setJobDescription(e.target.value);
          setJobDescriptionError(e.target.value.length < 150);
        }}
        disabled={isGenerating}
        placeholder="Enter job description..."
        style={{ width: '100%', padding: '8px', borderColor: jobDescriptionError ? 'red' : undefined }}
        aria-invalid={jobDescriptionError}
      />

      <div className="navigation-buttons">
        <div className="jd-button-group">
          <button
            className="aigen-btn"
            onClick={handleGenerateAI}
            disabled={
              isGenerating ||
              !jobTitle ||
              !salaryRange ||
              !experience ||
              !location
            }
          >
            <GenerateIcon /> Generate with AI
          </button>
          <label
            className="upload-btn"
            style={{
              pointerEvents: isGenerating || !jobTitle || !salaryRange || !experience || !location ? 'none' : 'auto',
              opacity: isGenerating || !jobTitle || !salaryRange || !experience || !location ? 0.5 : 1,
            }}
          >
            <UploadIcon /> Upload a file
            <input type="file" accept="application/pdf" hidden onChange={handleFileUpload} disabled={isGenerating || !jobTitle || !salaryRange || !experience || !location} />
          </label>
        </div>
        <button
          className="next-step-btn"
          onClick={handleNext}
          disabled={isGenerating || !isFormValid()}
        >
          Next step
        </button>
      </div>
    </form>
  );
};

export default JobDescriptionStep;
