import apiConfig from '../../../config/apiConfig';
import { getCookie } from '../../../components/cookieHelpers';

const API_GET_CV_EVALUATION_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getCvEval}`;
const API_GET_INTERVIEW_DETAILS_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getInterviewDetails}`;
const API_GET_VACANCY_DETAILS_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getVacancyDetails}`;
const API_GET_CV_DOWNLOAD = `${apiConfig.baseURL}${apiConfig.endpoints.getCvDownload}`;
const API_DELETE_CANDIDATE = `${apiConfig.baseURL}${apiConfig.endpoints.deleteCandidate}`;
const API_UPDATE_CANDIDATE_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.updateCandidate}`;

const API_TOKEN = apiConfig.token;
const KODELAB_PK = apiConfig.company_pk;

export const deleteCandidate = async (id) => {
    const token = getCookie('token');

    try {
        const response = await fetch(API_DELETE_CANDIDATE, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'api-key': API_TOKEN,
            },
            body: JSON.stringify({ pk: id }),
        });

        if (!response.ok) {
            const errorData = await response.json();
            const errorMessage = errorData?.message || 'Failed to delete candidate';
            throw new Error(errorMessage);
        }

        return response;
    } catch (error) {
        throw error;
    }
};

export const getCandidateCvDownload = async (candidatePk) => {
    const token = getCookie('token');
    try {
        const payload = { pk: candidatePk };
        const cvDownloadResponse = await fetch(API_GET_CV_DOWNLOAD, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'api-key': API_TOKEN
            },
            body: JSON.stringify(payload)
        });

        if (!cvDownloadResponse.ok) {
            throw new Error('Failed to fetch candidate cv download link');
        }

        const cvData = await cvDownloadResponse.json();
        return cvData.url;
    } catch (error) {
        console.error('Error fetching cv download link', error);
        throw error;
    }
};

export const getEvaluationDetails = async (candidatePk, companyPk) => {
    const token = getCookie('token');
    try {
        const payload = { candidate: candidatePk, company: companyPk };
        console.log('payload', payload);
        const evaluationResponse = await fetch(API_GET_CV_EVALUATION_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${token}`,
                'api-key': API_TOKEN
            },
            body: JSON.stringify(payload)
        });
        console.log(evaluationResponse)
        if (!evaluationResponse.ok) {
            throw new Error('Failed to fetch candidate evaluation details');
        }

        const evaluationData = await evaluationResponse.json();
        console.log('eval data', evaluationData);
        return evaluationData;
    } catch (error) {
        console.error('Error fetching evaluation details:', error);
        throw error;
    }
};

export const getInterviewDetails = async (interviewIds) => {
    const token = getCookie('token');
    const fetchedInterviews = [];

    for (const interviewId of interviewIds) {
        try {
            const interviewResponse = await fetch(API_GET_INTERVIEW_DETAILS_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'api-key': API_TOKEN
                },
                body: JSON.stringify({ interview: interviewId })
            });

            if (!interviewResponse.ok) {
                throw new Error(`Failed to fetch interview details for ID ${interviewId}`);
            }

            const interviewData = await interviewResponse.json();
            const vacancyId = interviewData.interview.job_description;

            const vacancyResponse = await fetch(API_GET_VACANCY_DETAILS_ENDPOINT, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`,
                    'api-key': API_TOKEN
                },
                body: JSON.stringify({ vacancy: vacancyId })
            });

            if (!vacancyResponse.ok) {
                console.warn(`Failed to fetch vacancy details for ID ${vacancyId} under interview ID ${interviewData.interview.pk}`);
                continue;
            }

            const vacancyData = await vacancyResponse.json();
            fetchedInterviews.push({
                interview: interviewData.interview,
                vacancy: vacancyData.vacancy
            });
        } catch (error) {
            console.error('Error fetching interview details:', error);
            throw error;
        }
    }
    return fetchedInterviews;
};

export const updateCandidate = async (candidateData) => {
    const token = getCookie('token');
    try {
        const response = await fetch(API_UPDATE_CANDIDATE_ENDPOINT, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'api-key': API_TOKEN,
                'Authorization': `Bearer ${token}`,
            },
            body: JSON.stringify(candidateData),
        });

        if (!response.ok) {
            throw new Error('Failed to update candidate');
        }

        const result = await response.json();
        return result;
    } catch (error) {
        console.error('Error updating candidate:', error);
        throw error;
    }
};