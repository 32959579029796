// src/hooks/useCandidateCvDownload.js
import { useState, useEffect } from 'react';
import { getCandidateCvDownload } from '../CandidateService';

const useCandidateCvDownload = (candidatePk) => {
    const [downloadLink, setDownloadLink] = useState('');
    const [isLoadingCvDownload, setIsLoadingCvDownload] = useState(true);
    const [cvDownloadError, setCvDownloadError] = useState(null);

    useEffect(() => {
        if (!candidatePk) return;

        const fetchData = async () => {
            try {
                const url = await getCandidateCvDownload(candidatePk);
                setDownloadLink(url);
            } catch (error) {
                setCvDownloadError('Failed to fetch candidate CV download link.');
            } finally {
                setIsLoadingCvDownload(false);
            }
        };

        fetchData();
    }, [candidatePk]);

    return { downloadLink, isLoadingCvDownload, cvDownloadError };
};

export default useCandidateCvDownload;