import React from 'react';
import industryOptions from '../../data/industryOptions';
import KodelabInput from '../../../../../components/KodelabInput'
import KodelabSelect from '../../../../../components/KodelabSelect';
import KodelabTextArea from '../../../../../components/KodelabTextArea';
import KodelabFilledPillButton from '../../../../../components/KodelabFilledPillButton';
import { ReactComponent as UploadFileIcon } from '../../../../../assets/UploadFileIcon.svg';

const numEmployeesOptions = [
  { value: '', label: 'Select number of employees' },
  { value: '1', label: '1-10' },
  { value: '11', label: '11-50' },
  { value: '51', label: '51-200' },
  { value: '201', label: '201-500' },
  { value: '501', label: '501-1,000' },
  { value: '1001', label: '1,001-5,000' },
  { value: '5001', label: '5,001-10,000' },
  { value: '10001', label: '10,001+' },
];

function CompanyOverview({ formState, errors, handleChange, handleNextStep, mode = 'create' }) {
  return (
    <div className="create-company-overview">
      <div className="company-details-header">
      <h3>{mode === 'create' ? 'Create Company' : mode === 'edit' ? 'Edit Company' : ''}</h3>
      </div>

      <div className="form-row">
        <div className="form-group">
          <KodelabInput
            name="name"
            label="Company Name"
            inputStyle={{ height: '52px' }}
            placeholder="Enter the company's name..."
            value={formState.name}
            onChange={handleChange} 
            required={true}
            />
          {errors.name && <span className="error-message">{errors.name}</span>}
        </div>
        <div className="form-group">
          <KodelabSelect
            label="Industry"
            name="industry"
            value={formState.industry || ''}
            onChange={handleChange}
            options={industryOptions.map((option) => ({
              value: option,
              label: option,
            }))}
            placeholder="Select Industry"
            inputStyle={{ height: '52px' }}
            required={true}
          />
          {errors.industry && <span className="error-message">{errors.industry}</span>}
        </div>
      </div>
      <div className="form-row">
        <div className="form-group">
          <KodelabInput
            label="Founded year"
            name="founded"
            value={formState.founded}
            onChange={handleChange}
            placeholder="Enter founded year, for example, 2014"
            type="number"
          />
        </div>
        <div className="form-group">
          <KodelabSelect
            label="Number of employees"
            name="num_employees"
            value={formState.num_employees || ''}
            onChange={handleChange}
            options={numEmployeesOptions}
            placeholder="Select the number of employees"
            inputStyle={{ height: '52px' }}
          />
        </div>
      </div>

      <div className="form-group">
        <label>Logo</label>
        <div className="logo-container">
          <div className="logo-placeholder">
            <UploadFileIcon />
            <h3>Upload Company Logo</h3>
            <p>(max 1MB)</p>
          </div>
        </div>
      </div>
      <div className="form-group">
        <KodelabTextArea
          name="mission"
          label="Company mission"
          placeholder="Enter the company's mission"
          value={formState.mission}
          onChange={handleChange}
          required={true}
        />
        {errors.mission && <span className="error-message">{errors.mission}</span>}
      </div>
      <div className="form-group">
        <KodelabTextArea
          name="vision"
          label="Company vision"
          placeholder="Enter the company's vision"
          value={formState.vision}
          onChange={handleChange}
          required={true}
        />
        {errors.vision && <span className="error-message">{errors.vision}</span>}
      </div>
      <div className="form-group">
        <KodelabTextArea
          name="description"
          label="Company description"
          placeholder="Enter the company's descriptions"
          value={formState.description}
          onChange={handleChange}
          required={true}
        />
        {errors.description && <span className="error-message">{errors.description}</span>}
      </div>
      <div className="new-company-footer">
        {/* Call handleNextStep with firstStep as true for inputValidation */}
        <KodelabFilledPillButton onClick={() => handleNextStep(true)}> 
          Next step
        </KodelabFilledPillButton>
      </div>
    </div>
  );
}

export default CompanyOverview;