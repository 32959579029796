import React, { useState, useEffect } from 'react';
import { Snackbar, Alert } from '@mui/material';
import './Login2.css';
import KodelabInput from '../../components/KodelabInput';
import KodelabPasswordInput from '../../components/KodelabPasswordInput';
import KodelabFilledPillButton from '../../components/KodelabFilledPillButton';
import Slide1 from '../../components/LoginSlides/Slide1'
import Slide2 from '../../components/LoginSlides/Slide2'
import Slide3 from '../../components/LoginSlides/Slide3'

const slides = [<Slide1 />, <Slide2 />, <Slide3 />];  // Components for the slideshow

function Login({ handleLogin }) {
    const [password, setPassword] = useState('');
    const [username, setUsername] = useState('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [openConstructionSnackbar, setOpenConstructionSnackbar] = useState(false);
    const [currentSlide, setCurrentSlide] = useState(0);

    const onLoginClick = async () => {
        try {
            const result = await handleLogin(username, password);
            if (!result) {
                setOpenSnackbar(true);
            } else {
                setOpenSnackbar(false);
            }
        } catch (error) {
            setOpenSnackbar(true);
        }
    };

    const handleCloseSnackbar = () => {
        setOpenSnackbar(false);
        setOpenConstructionSnackbar(false);
    };

    // Slideshow logic
    useEffect(() => {
        const slideInterval = setInterval(() => {
            // setCurrentSlide((prevSlide) => (prevSlide + 1) % slides.length);

            // setCurrentSlide(0);
        }, 5000); 

        return () => clearInterval(slideInterval);
    }, []);

    return (
        <div className="login-page">
            {/* Slideshow Section */}
            <div className="slideshow-container">
                <div className="slideshow">
                    <div className="slide-content">
                        {slides[currentSlide]}
                    </div>
                    <div className="slide-indicators">
                        {slides.map((_, index) => (
                            <span
                                key={index}
                                className={`indicator ${index === currentSlide ? 'active' : ''}`}
                                onClick={() => setCurrentSlide(index)}
                            ></span>
                        ))}
                    </div>
                </div>
            </div>

            {/* Login Form Section */}
            <div className="login-container">
                <div className='login-form'>
                    <Snackbar
                        open={openSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="error" sx={{ width: '100%' }}>
                            Login failed. Try again?
                        </Alert>
                    </Snackbar>

                    <Snackbar
                        open={openConstructionSnackbar}
                        autoHideDuration={6000}
                        onClose={handleCloseSnackbar}
                        anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
                    >
                        <Alert onClose={handleCloseSnackbar} severity="warning" sx={{ width: '100%' }}>
                            Not yet available. Sorry!
                        </Alert>
                    </Snackbar>

                    <h2>Log in</h2>
                    <p>New here? <a onClick={() => setOpenConstructionSnackbar(true)}>Create account now</a></p>
                    <div className='separator'>or</div>
                    <KodelabInput
                        label="Email"
                        placeholder="Enter your email"
                        onChange={(e) => setUsername(e.target.value)}
                    />
                    <KodelabPasswordInput
                        label="Password"
                        placeholder="Enter your password"
                        onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className='forgot-password'>
                        <a onClick={() => setOpenConstructionSnackbar(true)}>
                            Forgot password?
                        </a>
                    </div>
                    <KodelabFilledPillButton onClick={onLoginClick} style={{ width: '100%', height: '100%' }}>
                        Log in
                    </KodelabFilledPillButton>
                </div>
            </div>
        </div>
    );
}

export default Login;