import React from 'react';
import RadarChartPlaceholder from './RadarChartPlaceholder';

const CandidateEvaluation = ({ candidateScores, skillAverages, evaluationSummary, vacancy}) => {

  const labelsWithoutVacancy = [
    'Behavioral & Soft Skills',
    'Diversity & Inclusion',
    'Company Culture Fit',
    'Innovation & Continuous Improvement',
    'Problem Solving & Analytical Skills',
    'Leadership & Management'
  ];
  
  const dataWithoutVacancy = [
    skillAverages["Behavioral_and_Soft_Skills"],
    skillAverages["Diversity_and_Inclusion"],
    skillAverages["Company_Culture_Fit"],
    skillAverages["Innovation_and_Continuous_Improvement"],
    skillAverages["Problem_Solving_and_Analytical_Skills"],
    skillAverages["Leadership_and_Management"]
  ];
  
  const labelsWithVacancy = [
    'Behavioral & Soft Skills',
    'Company Culture Fit',
    'Role Specific Knowledge',
    'Innovation & Continuous Improvement',
    'Problem Solving & Analytical Skills',
    'Leadership & Management'
  ];
  
  const dataWithVacancy = [
    skillAverages["Behavioral_and_Soft_Skills"],
    skillAverages["Company_Culture_Fit"],
    skillAverages["Role_Specific_Knowledge"],
    skillAverages["Innovation_and_Continuous_Improvement"],
    skillAverages["Problem_Solving_and_Analytical_Skills"],
    skillAverages["Leadership_and_Management"]
  ];
  
  const labels = vacancy ? labelsWithVacancy : labelsWithoutVacancy;
  const data = vacancy ? dataWithVacancy : dataWithoutVacancy;
  

  return (
    <div className="candidate-evaluation">
      {!vacancy && <h2>Evaluation Details</h2>}
      <div className="evaluation-overview">
        <div className="evaluation-scores">
          <div className="score-circle">
            <p>Company fit</p>
            <div className="circle">{candidateScores.CompanyFitScore}%</div>
          </div>
          <div className="score-circle">
           <p>{vacancy ? "Vacancy score" : "CV score"}</p>
           <div className="circle">{vacancy ? candidateScores.VacancyScore : candidateScores.CVScore}%</div>
          </div>

        </div>
        {/* The following commented out radarchart is the culprit! */}
        <RadarChartPlaceholder labels={labels} data={data} />
        <div className="skills-list">
        {labels.map((label, index) => (
          <p key={label}>
            {label}: {data[index] || "N/A"}
          </p>
        ))}
        </div>
      </div>
      {/* <div className="evaluation-summary">
        <h3>Overall Evaluation</h3>
        <p>{evaluationSummary !== undefined ? evaluationSummary : 'Evaluation summary not available'}</p>
      </div> */}
    </div>
  );
};

export default CandidateEvaluation;
