import React, { useState, useEffect, useRef } from 'react';
import CandidateEvaluation from './CandidateEvaluation.js';
import InterviewDetails from './InterviewDetails.js';
import PersonalInformationCard from './PersonalInformationCard.js';
import InterviewQuestions from '../vacancies/InterviewQuestions.js';
import back_arrow from '../../../assets/BsChevronLeft.png';
import { formatDuration } from '../../../utils/Formatters.js';
import { ReactComponent as LinkedInIcon } from '../../../assets/LinkedInIcon.svg';
import { ReactComponent as DotIcon } from '../../../assets/DotIcon.svg';

import DownloadButton from '../../../components/DownloadButton.js';

import useCandidateEvaluation from './hooks/useCandidateEvaluation.js';
import useCandidateCvDownload from './hooks/useCandidateCvDownload.js';
import useInterviewDetails from './hooks/useInterviewDetails.js';

const CandidateDetails = ({ candidate, containerRef, selectedCompany }) => {
  const [evaluationSummary, setEvaluationSummary] = useState('');
  const [candidateScores, setCandidateScores] = useState({});
  const [skillAverages, setSkillAverages] = useState({});
  const [showEvaluationReport, setShowEvaluationReport] = useState(false);
  const [selectedInterview, setSelectedInterview] = useState(null);
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);
  const [selectedChatHistory, setSelectedChatHistory] = useState(null);

  const popupRef = useRef(null);
  const historyPopupRef = useRef(null);

  const { evaluationData, isLoadingEvaluation, evaluationError } = useCandidateEvaluation(candidate?.pk, selectedCompany?.company?.pk);
  const { downloadLink, isLoadingCvDownload, cvDownloadError } = useCandidateCvDownload(candidate?.pk);
  const { interviewDetails, isLoadingInterviews, interviewFetchError } = useInterviewDetails(candidate?.interviews);

  useEffect(() => {
    if (interviewDetails) {
      console.log(interviewDetails);
    }
  }, [interviewDetails]);

  useEffect(() => {
    if (containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth'
      });
    }
  }, [showEvaluationReport]);

  useEffect(() => {
    if (candidate) {
      console.log('candidate here', candidate);
      setShowEvaluationReport(false);
    }
  }, [candidate]);

  useEffect(() => {
    if (evaluationData) {
      const skillsEvaluation = evaluationData.evaluation?.skills_evaluation || [];

      const categoryAverages = {};
      Object.entries(skillsEvaluation).forEach(([categoryKey, skills]) => {
        const total = Object.values(skills).reduce((acc, skill) => {
          return acc + parseInt(skill, 10);
        }, 0);
        const average = Math.round(total / Object.keys(skills).length);
        categoryAverages[categoryKey] = average;
      });

      const candidatesScores = {
        CVScore: evaluationData.evaluation?.cv_overall_grade || 'N/A',
        CompanyFitScore: evaluationData.evaluation?.company_fit_grade || 'N/A',
        VacancyScore: evaluationData.evaluation?.vacancy_fit_grade || 'N/A',
      };

      setSkillAverages(categoryAverages);
      setEvaluationSummary(evaluationData.evaluation?.summary || 'N/A');
      setCandidateScores(candidatesScores);
    }
  }, [evaluationData]);

  useEffect(() => {
    const handleMouseDown = (event) => {
      if (selectedChatHistory && historyPopupRef.current && !historyPopupRef.current.contains(event.target)) {
        setSelectedChatHistory(null);
      }
      if (isPopupVisible && popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupVisible(false);
      }
    };

    document.addEventListener('mousedown', handleMouseDown);

    return () => {
      document.removeEventListener('mousedown', handleMouseDown);
    };
  }, [selectedChatHistory, isPopupVisible]);

  const handleGoBack = () => {
    setShowEvaluationReport(false);
    setSelectedInterview(null);
  };

  const handleEvaluationReportClick = (interview) => {
    setSelectedInterview(interview);
    setShowEvaluationReport(true);
  };

  const handleOpenVacancyPopup = (job) => {
    setSelectedJob(job);
    setIsPopupVisible(true);
  };

  const handleCloseVacancyPopup = () => {
    setIsPopupVisible(false);
  };

  const renderEvaluationDetails = (interview) => {
    if (!interview || !interview.evaluation) return <div>No evaluation details available.</div>;

    return interview?.evaluation?.questions_scores?.map((evalDetail, index) => (
      <div key={index} className="evaluation-item">
        <strong>Question {evalDetail?.question_number || 'N/A'}</strong><p>{evalDetail?.question || 'Question not available'}</p>
        <strong>Candidate answer</strong><p>{evalDetail?.answer || 'Answer not available'}</p>
        <strong>Score</strong><p>{evalDetail?.score !== undefined ? evalDetail.score : 'N/A'}</p>
        <strong>Observation</strong><p>{evalDetail?.explanatoon || 'Observation not available'}</p>
        <strong>Agent answer</strong><p>{evalDetail?.agent_answer || 'Agent answer not available'}</p>
        <strong>AI prob</strong><p>{evalDetail?.aiprob !== undefined ? evalDetail.aiprob : 'N/A'}</p>
      </div>
    )) || <p>No evaluation details available.</p>;

  };

  const contactDetails = [
    candidate?.Personal_Information.email || '',
    candidate?.Personal_Information.phone || '',
    candidate?.address || ''
  ].filter(detail => Boolean(detail)).join(' | ');

  return candidate ? (
    <div className="candidate-details" ref={containerRef}>
      <div className="candidate-details-header">
        <div>
          <h1 className="candidate-name-title" style={{ fontSize: '36px' }}>{candidate.name}</h1>
          <p className="candidate-position-subtitle">
            {candidate.Work_Experience?.[0]?.Title || 'Title not available'} at {candidate.Work_Experience?.[0]?.Company || 'Company not available'}
          </p>
        </div>
        <div className="candidate-contacts-container">
          <div className="candidate-contacts">
            <span>{candidate.Personal_Information.linkedin && (
              <a href={candidate.Personal_Information.linkedin.startsWith('http') ? candidate.Personal_Information.linkedin : `https://${candidate.Personal_Information.linkedin}`} target="_blank" rel="noopener noreferrer">
                <LinkedInIcon alt="LinkedIn" className="candidate-contact-icon" />
              </a>
            )}</span>
            <DownloadButton resumeFile={candidate.resume_file}
              resumeFilename={candidate.resume_filename}
              resumeFiletype={candidate.resume_filetype} url={downloadLink} />
          </div>
          <div className="candidate-location">
            <p>{contactDetails}</p>
          </div>
        </div>
      </div>


      {showEvaluationReport && selectedInterview ? (
        <div>
          <div className="vacancy-eval-back-btn">
            <img src={back_arrow} className="back-arrow-icon" alt="Back" />
            <p onClick={handleGoBack}>Go back</p>
          </div>
          <div className='eval-report-header'>
            <div className='left-side'>
              <h4>{selectedInterview.vacancy?.job_title}</h4>
              <DotIcon className='dot' />
              <p>evaluation report</p>
            </div>
            <div className='right-side'>
              <h4>{selectedInterview.interview?.evaluation?.overall_score
                ? `${selectedInterview.interview.evaluation.overall_score.toFixed(2)}/5`
                : 'N/A'}
              </h4>
            </div>
          </div>
          <div className="evaluation-report">
            <CandidateEvaluation candidateScores={candidateScores} skillAverages={skillAverages} evaluationSummary={evaluationSummary} vacancy={true} />
            <div className='evaluation-headers'>
              <p><strong>Interview Duration:</strong> {formatDuration(selectedInterview.interview.elapsed)}</p>
              <p
                className="evaluation-chathistory"
                onClick={() => setSelectedChatHistory(selectedInterview.interview.interview)}
              >
                Chat History
              </p>

              {selectedChatHistory && (
                <div className="popup-overlay">
                  <div className="popup-content" ref={historyPopupRef}>
                    <button className="popup-close" onClick={() => setSelectedChatHistory(null)}>×</button>
                    <h2 className="popup-title">Chat History</h2>
                    <div className="popup-content-text chat-messages">
                      {selectedChatHistory.slice().reverse().map((message, index) => (
                        <div key={index} className={`message ${message.role === 'assistant' ? 'assistant' : 'user'}`}>
                          <p>{message.content}</p>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              )}


            </div>
            <div>{renderEvaluationDetails(selectedInterview.interview)}</div>
          </div>
        </div>
      ) : (
        <div>
          <div className="candidate-evaluation card">
            <CandidateEvaluation candidateScores={candidateScores} skillAverages={skillAverages} evaluationSummary={evaluationSummary} vacancy={false} />
          </div>
          <PersonalInformationCard
            portfolio={candidate.Personal_Information?.portfolio || candidate.Personal_Information?.website || candidate.Personal_Information?.Portfolio}
            topSkills={candidate.Skill || []}
            education={candidate.Education || []}
            experience={candidate.Work_Experience || []}
          />

          <div className="candidate-interview-details inviscard">
            {isPopupVisible && (
              <div className="popup-overlay">
                <div className="popup-content" ref={popupRef}>
                  <button className="popup-close" onClick={handleCloseVacancyPopup}>×</button>
                  <h2 className="popup-title">{selectedJob.vacancy.job_title}</h2>
                  <div className="popup-content-text">
                    <p>{selectedJob.vacancy.job_description}</p>
                    <InterviewQuestions questions={selectedJob.vacancy.questions.questions} />
                  </div>
                </div>
              </div>
            )}
            <h2>Interviews</h2>
            <InterviewDetails
              interviewDetails={interviewDetails}
              isLoadingInterviews={isLoadingInterviews}
              interviewFetchError={interviewFetchError}
              onEvaluationReportClick={handleEvaluationReportClick}
              handleOpenVacancyPopup={handleOpenVacancyPopup}
              selectedCompany={selectedCompany}
            />
          </div>
        </div>
      )}
    </div>
  ) : (
    //You can use this to show a message when the candidates are loading
    <div className="no-candidate-selected"></div>
  );
};

export default CandidateDetails;
