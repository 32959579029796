import React, { useState, useEffect, useMemo, useRef, useCallback } from 'react';
import './Candidates.css';
import apiConfig from '../../../config/apiConfig';
import CandidateList from './CandidateList';
import CandidateDetails from './CandidateDetails';
import EditCandidate from './EditCandidate';
import { getCookie } from '../../../components/cookieHelpers';
import { deleteCandidate } from './CandidateService.js';

const API_GET_CANDIDATES_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getCandidates}`;
const API_GET_COMPANIES_ENDPOINT = `${apiConfig.baseURL}${apiConfig.endpoints.getCompanies}`;
const API_TOKEN = apiConfig.token;
const COMPANY_NAME = apiConfig.company_name;

function Candidates() {
  const [candidates, setCandidates] = useState(null);
  const [selectedCandidate, setSelectedCandidate] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [selectedCompany, setSelectedCompany] = useState(null);
  const [isLoadingCandidates, setIsLoadingCandidates] = useState(false);
  const [fetchError, setFetchError] = useState(null);
  const containerRef = useRef(null);

  const token = getCookie('token');

  useEffect(() => {
    if (selectedCandidate && containerRef.current) {
      containerRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
    }
  }, [selectedCandidate]);

  useEffect(() => {
    fetchCompanies();
  }, []);

  useEffect(() => {
    if (selectedCompany) {
      console.log(selectedCompany);
    }
  }, [selectedCompany]);

  const fetchCompanies = async () => {
    const token = getCookie('token');
    try {
      const response = await fetch(API_GET_COMPANIES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
          'api-key': API_TOKEN,
        },
        body: JSON.stringify({ pk: '*' }),
      });

      const data = await response.json();
      setCompanies(data.companies);
      if (data.companies && data.companies.length > 0) {
        setSelectedCompany(data.companies[0]);
      }
    } catch (error) {
      console.warn(error);
    }
  };

  const fetchCandidates = useCallback(async () => {
    if (!selectedCompany) return;

    setIsLoadingCandidates(true);
    setFetchError(null);

    try {
      const response = await fetch(API_GET_CANDIDATES_ENDPOINT, {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${token}`,
          'Content-Type': 'application/json',
          'api-key': API_TOKEN,
        },
        body: JSON.stringify({ company: selectedCompany?.company?.name }),
      });
      const data = await response.json();
      console.log(data);
      
      data.candidates.sort((a, b) => {
        const dateA = a.creation_date || 0; // If no creation date, treat as oldest
        const dateB = b.creation_date || 0;
        return dateB - dateA;
      });

      setCandidates(data.candidates);
      setSelectedCandidate(data.candidates[0]);
      console.log('Fetched candidates:', data.candidates);
      setIsLoadingCandidates(false);
    } catch (error) {
      setFetchError(error);
      setIsLoadingCandidates(false);
    }
  }, [selectedCompany, token]);

  useEffect(() => {
    fetchCandidates();
  }, [fetchCandidates]);

  const handleCandidateClick = (candidate) => {
    setSelectedCandidate(candidate);
    setIsEditing(false);
  };

  const handleEditCandidate = (candidate) => {
    setSelectedCandidate(candidate);
    setIsEditing(true);
  };

  const handleCancelEdit = () => {
    setIsEditing(false);
  };

  const handleDeleteCandidate = async (id) => {
    try {
      await deleteCandidate(id);
      fetchCandidates();
      alert('Candidate deleted successfully!');
    } catch (error) {
      alert('There was a problem deleting the candidate');
    }
  };

  return (
    <div className="candidates-page">
      <CandidateList
        companies={companies}
        selectedCompany={selectedCompany}
        setSelectedCompany={setSelectedCompany}
        candidates={candidates}
        isLoadingCandidates={isLoadingCandidates}
        fetchError={fetchError}
        selectedCandidate={selectedCandidate}
        onCandidateClick={handleCandidateClick}
        onEditCandidate={handleEditCandidate}
        onDeleteCandidate={handleDeleteCandidate}
      />
      <main className="candidates-main">
        {isEditing ? (
          <EditCandidate
            candidate={selectedCandidate}
            onCancelEdit={handleCancelEdit}
            onFetchCandidates={fetchCandidates}
          />
        ) : (
          <CandidateDetails candidate={selectedCandidate} containerRef={containerRef} selectedCompany={selectedCompany} />
        )}
      </main>
    </div>
  );
}

export default Candidates;


// useEffect(() => {
//   if (candidatesData?.candidates) {
//     setCandidates(candidatesData.candidates);
//     setSelectedCandidate(candidatesData.candidates[0]);
//     console.log('Fetched candidates:', candidatesData.candidates);
//   }
// }, [candidatesData]);