// /hooks/useCompanies.js

import { useState, useEffect, useCallback } from 'react';
import { fetchCompanies } from '../CompaniesService';

const useCompanies = () => {
  const [companies, setCompanies] = useState([]);
  const [isLoadingCompanies, setIsLoadingCompanies] = useState(true);
  const [companiesError, setCompaniesError] = useState(null);

  const loadCompanies = useCallback(async () => {
    setIsLoadingCompanies(true);
    setCompaniesError(null);
    try {
      const data = await fetchCompanies();
      setCompanies(data);
    } catch (error) {
      setCompaniesError(error.message || 'Failed to fetch companies');
    } finally {
      setIsLoadingCompanies(false);
    }
  }, []);

  useEffect(() => {
    loadCompanies();
  }, [loadCompanies]);

  return { companies, isLoadingCompanies, companiesError, refetchCompanies: loadCompanies };
};

export default useCompanies;