import React from 'react';
import KodelabFilledPillButton from '../../../../../components/KodelabFilledPillButton';
import KodelabCircularBackButton from '../../../../../components/KodelabCircularBackButton';
import KodelabInput from '../../../../../components/KodelabInput';
import KodelabSelect from '../../../../../components/KodelabSelect';

import { ReactComponent as DeleteIcon } from '../../../../../assets/DeleteIcon.svg'

const platformOptions = [
  { value: '', label: 'Select Platform' },
  { value: 'Discord', label: 'Discord' },
  { value: 'Facebook', label: 'Facebook' },
  { value: 'Instagram', label: 'Instagram' },
  { value: 'LinkedIn', label: 'LinkedIn' },
  { value: 'Pinterest', label: 'Pinterest' },
  { value: 'Reddit', label: 'Reddit' },
  { value: 'Telegram', label: 'Telegram' },
  { value: 'TikTok', label: 'TikTok' },
  { value: 'Twitter', label: 'Twitter' },
  { value: 'YouTube', label: 'YouTube' },
];

function Contact({ formState, handleChange, handlePreviousStep, newAddress, setNewAddress, otherAddresses, addAddress, removeAddress, newSocialMedia, handleSocialMediaChange, addSocialMedia, removeSocialMedia, socialMedias, handleSubmit }) {
  return (
    <div className="company-contact">
      <div>
        <div className="company-details-header">
          <h3>Contact</h3>
        </div>

        <div className="form-row">
          <div className="form-group">
            <KodelabInput
              label="Company email"
              name="email"
              placeholder="Enter email..."
              value={formState.email}
              onChange={handleChange}
            />
          </div>
          <div className="form-group">
            <KodelabInput
              label="Company website"
              name="website"
              placeholder="Enter website..."
              value={formState.website}
              onChange={handleChange}
            />
          </div>
        </div>
        <div className="form-row">
          <div className="form-group">
            <KodelabInput
              label="Primary location"
              name="headquarters"
              placeholder="Enter primary location..."
              value={formState.headquarters}
              onChange={handleChange}
            />
          </div>

        </div>
        <KodelabInput
          label="Additional location"
          placeholder="Enter additional location..."
          value={newAddress}
          onChange={(e) => setNewAddress(e.target.value)}
        />
        <KodelabFilledPillButton onClick={addAddress} outlined style={{ padding: '11px 35px' }}>
          Add
        </KodelabFilledPillButton>
        {/* List of Added Additional Locations */}
        <ul className="addable-list">
          {otherAddresses.map((address, index) => (
            <li key={index} className="addable-item">
              <div className="addable-content">
                <p>{address}</p>
              </div>
              <button type="button" className="delete-button" onClick={() => removeAddress(index)}>
                <DeleteIcon />
              </button>
            </li>
          ))}
        </ul>

        {/* Social Media Section */}
        <div className="company-details-header">
          <h3>Social media</h3>
        </div>
        <div className="form-row">
          <div className="form-group">
            <KodelabSelect
              label=""
              name="platform"
              value={newSocialMedia.platform || ''}
              onChange={handleSocialMediaChange}
              options={platformOptions}
              placeholder="Select social media"
              inputStyle={{ height: '52px' }}
            />
          </div>
          <div className="form-group">
            <KodelabInput
              type="url"
              name="url"
              placeholder="Enter URL..."
              value={newSocialMedia.url}
              onChange={handleSocialMediaChange}
            />
          </div>

        </div>
        <KodelabFilledPillButton onClick={addSocialMedia} outlined style={{ padding: '11px 35px' }}>
          Add
        </KodelabFilledPillButton>

        {/* List of Added Social Media */}
        <ul className="addable-list">
          {Object.entries(socialMedias).map(([platform, url]) => (
            <li key={platform} className="addable-item">
              <div className="addable-content">
                <strong>{platform}</strong>: {url}
              </div>
              <button type="button" className="delete-button" onClick={() => removeSocialMedia(platform)}>
                <DeleteIcon />
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="new-company-footer">
        <KodelabCircularBackButton onClick={handlePreviousStep} />
        <KodelabFilledPillButton onClick={handleSubmit} style={{ padding: '15px 30px' }}>
          Complete
        </KodelabFilledPillButton>
      </div>
    </div>
  );
}

export default Contact;