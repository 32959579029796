import React from 'react';
import KodelabFilledPillButton from '../../../../../components/KodelabFilledPillButton';
import KodelabCircularBackButton from '../../../../../components/KodelabCircularBackButton';
import KodelabInput from '../../../../../components/KodelabInput';
import KodelabTextArea from '../../../../../components/KodelabTextArea';
import { ReactComponent as DeleteIcon } from '../../../../../assets/DeleteIcon.svg'

function BusinessPortfolio({ handleNextStep, handlePreviousStep, productDetails, serviceDetails, handleProductChange, handleServiceChange, addProduct, removeProduct, addService, removeService, products, services }) {
  return (
    <div className="company-business-portfolio">
      <div>
        <div className="company-details-header">
          <h3>
            Business Portfolio
          </h3>
        </div>

        {/* Products Section */}
        <div>
          <KodelabInput
            label="Products"
            name="name"
            placeholder="Enter product name..."
            value={productDetails.name}
            onChange={handleProductChange}
          />
          <KodelabTextArea
            name="description"
            placeholder="Enter product details..."
            value={productDetails.description}
            onChange={handleProductChange}
            inputStyle={{ marginBottom: '10px' }}
          />
        </div>
        <KodelabFilledPillButton onClick={addProduct} outlined style={{ padding: '11px 35px' }}>
          Add
        </KodelabFilledPillButton>

        <ul className="addable-list">
          {products.map((product, index) => (
            <li key={index} className="addable-item">
              <div className="addable-content">
                <strong>{product?.product.name}</strong>
                <p>{product?.product.description}</p>
              </div>
              <button type="button" className="delete-button" onClick={() => removeProduct(index)}>
                <DeleteIcon />
              </button>
            </li>
          ))}
        </ul>


        {/* Services Section */}
        <div>
          <KodelabInput
            label="Services"
            name="name"
            placeholder="Enter service name..."
            value={serviceDetails.name}
            onChange={handleServiceChange}
          />
          <KodelabTextArea
            name="description"
            placeholder="Enter service details..."
            value={serviceDetails.description}
            onChange={handleServiceChange}
            inputStyle={{ marginBottom: '10px' }}
          />
        </div>
        <KodelabFilledPillButton onClick={addService} outlined style={{ padding: '11px 35px' }}>
          Add
        </KodelabFilledPillButton>

        {/* List of Added Services */}
        <ul className="addable-list">
          {services.map((service, index) => (
            <li key={index} className="addable-item">
              <div className="addable-content">
                <strong>{service?.service.name}</strong>
                <p>{service?.service.description}</p>
              </div>
              <button type="button" className="delete-button" onClick={() => removeService(index)}>
                <DeleteIcon />
              </button>
            </li>
          ))}
        </ul>
      </div>
      <div className="new-company-footer">
        <KodelabCircularBackButton onClick={handlePreviousStep} />
        <KodelabFilledPillButton onClick={handleNextStep} style={{ padding: '15px 30px' }}>
          Next step
        </KodelabFilledPillButton>
      </div>
    </div>
  );
}

export default BusinessPortfolio;