import React from 'react';
import './VacancyDetails.css';
import InterviewQuestions from './InterviewQuestions';
import KodelabFilledPillButton from '../../../components/KodelabFilledPillButton';

const VacancyDetails = ({ selectedVacancy, vacancyQuestions, copyLinkToClipboard, containerRef }) => {
  return (
    <div className="vacancy-details" ref={containerRef}>
      <div className="vacancy-header">
        <div className="header-content">
          <div className="header-content-horizontal">
            <h2>{selectedVacancy.job_title}</h2>
            <span className={`status-badge ${selectedVacancy.active ? 'active' : 'inactive'}`}>
              {selectedVacancy.active ? 'Active' : 'Inactive'}
            </span>
          </div>
          <div className="vacancy-info">
            <p>{new Date(selectedVacancy.opened * 1000).toLocaleDateString()}</p>
            {selectedVacancy.closed && (
              <p>{new Date(selectedVacancy.closed * 1000).toLocaleDateString()}</p>
            )}
          </div>

        </div>
        <div className="header-actions">
          <KodelabFilledPillButton
            outlined={true}
            style={{ padding: '8px 16px', color: '#202020', fontSize: '16px' }}
            onClick={() => copyLinkToClipboard(selectedVacancy.pk)}
          >
            Copy interview link
          </KodelabFilledPillButton>
        </div>
      </div>
      <div className="vacancy-description card">
        <h3>Job Description</h3>
        {typeof selectedVacancy.job_description === 'string' && (
          <pre>{selectedVacancy.job_description}</pre>
        )}
      </div>
      <div className="vacancy-questions card">
        <InterviewQuestions questions={vacancyQuestions} />
      </div>
    </div>
  );
};

export default VacancyDetails;
