// src/hooks/useInterviewDetails.js
import { useState, useEffect } from 'react';
import { getInterviewDetails } from '../CandidateService';

const useInterviewDetails = (interviewIds) => {
    const [interviewDetails, setInterviewDetails] = useState([]);
    const [isLoadingInterviews, setIsLoadingInterviews] = useState(true);
    const [interviewFetchError, setInterviewFetchError] = useState(null);

    useEffect(() => {
        if (!interviewIds || interviewIds.length === 0) {
            setInterviewDetails([]);
            setIsLoadingInterviews(false);
            return;
        }

        const fetchData = async () => {
            try {
                const data = await getInterviewDetails(interviewIds);
                setInterviewDetails(data);
            } catch (error) {
                setInterviewFetchError('Failed to fetch interview details. Please try again.');
            } finally {
                setIsLoadingInterviews(false);
            }
        };

        fetchData();
    }, [interviewIds]);

    return { interviewDetails, isLoadingInterviews, interviewFetchError };
};

export default useInterviewDetails;